var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal is-active"
  }, [_c('div', {
    staticClass: "modal-background"
  }), _c('div', {
    staticClass: "modal-content"
  }, [_c('h1', {
    staticClass: "modal-content__title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    staticClass: "modal-content__description"
  }, [_vm._v(_vm._s(_vm.description))]), _c('button', {
    staticClass: "button modal-content__button",
    on: {
      "click": function ($event) {
        return _vm.$emit('toggle-modal');
      }
    }
  }, [_vm._v(" CERRAR ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }