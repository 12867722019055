import apiAlegraStoresService from './api-alegra-stores'

const campaignsService = {}

campaignsService.getActiveCampaigns = idStore => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await apiAlegraStoresService.get(`/stores/${idStore}/banner/current`)
      resolve(data)
    } catch (e) {
      reject(e)
    }
  })
}

export default campaignsService
