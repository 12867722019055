<template>
  <div class="info-banner">
    <figure class="info-banner__icon">
      <img src="@/assets/icon-info-lilac.svg" alt="Information" />
    </figure>
    <p class="info-banner__text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'info-banner',
  props: {
    text: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
  .info-banner {
    border: 1px solid var(--color-info-border);
    border-radius: 12px;
    padding: 15px 20px 15px 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: var(--color-info-bg);

    &__icon {
      flex: none;
      display: flex;
      img {
        width: 32px;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 20px;
      color: var(--color-dark);
    }
  }
</style>
