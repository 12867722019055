import store from '@/store'
import currency from 'currency.js'
import Big from 'big.js'

const currencyUtil = {}

currencyUtil.convertcurrency = amount => {
  if (typeof amount !== 'number') return amount

  const currencySymbol = store.get('shop/shop.config.currency.symbol')

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  const value = String(formatter.format(amount)).replace('$', '')
  return currencySymbol + value
}

export function currencyFormatter(amount, options = {}) {
  const shopConfig = store.getters['shop/shopConfig']
  const { showSymbol } = options

  let {
    decimalPrecision,
    decimalSeparator,
    currency: { symbol }
  } = shopConfig

  const precision = parseInt(decimalPrecision) === 0 ? 0 : 2
  if (decimalSeparator === undefined) {
    decimalSeparator = ','
  }
  const currencyOptions = {
    separator: decimalSeparator,
    precision,
    symbol
  }

  if (showSymbol === false) {
    currencyOptions.symbol = ''
  }

  return currency(amount, currencyOptions).format()
}

export function formatCurrencyForCalculations(amount) {
  return new Big(amount).toFixed(6)
}

export default currencyUtil
