import store from '@/store'
import { ALLOWED_PAYMENT_METHODS } from '@/utils/constants'

const { PAYU, MERCADO_PAGO, NEQUI, VISANET /*, GREENPAY */, PAYPAL } = ALLOWED_PAYMENT_METHODS

const getters = {
  conventionalPayments(state) {
    if (state.paymentMethods && state.paymentMethods.length) {
      return state.paymentMethods.filter(method => {
        if (
          !method.provider ||
          [PAYU, MERCADO_PAGO, NEQUI, VISANET /*, GREENPAY */, PAYPAL].includes(method?.provider)
        ) {
          return method
        }
      })
    }
    return []
  },
  automaticPayments(state) {
    if (state.paymentMethods && state.paymentMethods.length) {
      return state.paymentMethods.filter(method => {
        if ([PAYU, MERCADO_PAGO, NEQUI, VISANET /*, GREENPAY */, PAYPAL].includes(method?.provider)) {
          return method
        }
      })
    }
    return []
  },
  otherPayments(state) {
    if (state.paymentMethods && state.paymentMethods.length) {
      return state.paymentMethods.filter(method => {
        if (method.provider && method.provider === 'WHATSAPP') {
          return method
        }
      })
    }
    return []
  },
  landingAlegraTienda(state) {
    const applicationVersion = store.get('shop/shop@config.applicationVersion')

    let url = 'https://alegra.com/'
    switch (applicationVersion) {
      case 'colombia':
        url += 'colombia/'
        break
      case 'argentina':
        url += 'argentina/'
        break
      case 'chile':
        url += 'chile/'
        break
      case 'costaRica':
        url += 'costarica/'
        break
      case 'spain':
        url += 'es/'
        break
      case 'mexico':
        url += 'mexico/'
        break
      case 'panama':
        url += 'panama/'
        break
      case 'peru':
        url += 'peru/'
        break
      case 'republicaDominicana':
        url += 'rdominicana/'
        break
      case 'usa':
        url += 'usa/'
        break

      default:
        break
    }
    url +=
      'tienda/?utm_source=alegra-tienda&utm_medium=referral&utm_campaign=all-alegra-tienda-brand-footer&utm_content=abierta-clicks&utm_term=enlace'
    return url
  },
  shopConfig(state) {
    return state?.shop?.config || {}
  },
  shopLogo(state) {
    // Si es un catálogo, no tiene logo, se muestra el nombre. Cuando el backend lo permita, se debe usar el logo de Alegra Contabilidad
    if (state.shop.type === 'catalog') return false

    // Logo subido desde ATA, tiene prioridad
    if (state.shop.storeLogo) return state.shop.storeLogo

    // Logo subido desde Alegra Contabilidad
    return state.shop.logo
  }
}

export default getters
