import apiAlegraStoresService from './api-alegra-stores'
import config from '@/services/config'

const storeService = {}

storeService.getByIdCompany = idCompany => {
  return apiAlegraStoresService
    .get('/stores', { params: { idCompany } })
    .then(res => res.data)
    .catch(error => {
      return error
    })
}

storeService.getShopBySubdomain = subdomain => {
  return apiAlegraStoresService
    .get('/stores' + '/domain/' + subdomain)
    .then(res => res.data)
    .catch(error => {
      return error
    })
}
// Docs departamentos
// https://apimicroserviciodeinformacionespecificadeconfiguracionlambda.docs.apiary.io/#reference/departamentos/listar-todos-los-departamentos-de-un-pais-determinado/get
storeService.getDepartments = params => {
  return apiAlegraStoresService
    .get(`${config.apiAlegraConfig}/departments`, { params })
    .then(res => res)
    .catch(e => e)
}

// Docs ciudades
// https://apimicroserviciodeinformacionespecificadeconfiguracionlambda.docs.apiary.io/#introduction/coleccion-de-ciudades-(/cities)
storeService.getCities = params => {
  return apiAlegraStoresService
    .get(`${config.apiAlegraConfig}/cities`, { params })
    .then(res => res)
    .catch(e => e)
}

storeService.getDistricts = params => {
  return apiAlegraStoresService
    .get(`${config.apiAlegraConfig}/districts`, { params })
    .then(res => res)
    .catch(e => e)
}

storeService.getIdentificationTypes = params => {
  return apiAlegraStoresService
    .get(`${config.apiAlegraConfig}/identification-types`, { params })
    .then(res => res)
    .catch(e => e)
}

storeService.getPaymentMethods = idStore => {
  return apiAlegraStoresService
    .get(`/stores/${idStore}/payment-methods`, { params: { status: 'active' } })
    .then(res => res.data)
    .catch(e => e)
}

export default storeService
