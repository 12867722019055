var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Header', [_c('div', {
    staticClass: "level"
  }, [_c('div', {
    staticClass: "level-left"
  }, [_vm.shop ? _c('a', {
    staticClass: "link-logo",
    attrs: {
      "href": "/"
    }
  }, [_vm.shopLogo ? _c('img', {
    staticClass: "image image-logo",
    attrs: {
      "src": _vm.shopLogo
    }
  }) : _c('h2', {
    staticClass: "title-shop"
  }, [_vm._v(_vm._s(_vm.shop.name))])]) : _vm._e()]), _c('div', {
    staticClass: "level-center"
  }, [_c('div', {
    staticClass: "level-item level-search"
  }, [_c('app-input-search')], 1)]), _c('div', {
    staticClass: "level-right"
  }, [_c('div', {
    staticClass: "level-item level-shopping-cart"
  }, [_c('span', [_vm._v("MI CARRITO")]), _c('div', {
    staticClass: "shopping-cart pointer",
    on: {
      "click": _vm.goToShoppingCart
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-shopping-car.svg')
    }
  }), _vm.productsInCart.length ? _c('div', {
    staticClass: "badge has-text-centered"
  }, [_c('p', [_vm._v(_vm._s(_vm.unitsInCart))])]) : _vm._e()], 1)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }