import apiAlegraStoresService from './api-alegra-stores'

const couponsService = {}

couponsService.verifyCoupon = (idStore, promoCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await apiAlegraStoresService.get(
        `/stores/${idStore}/discount-coupon/verify?code=${promoCode}`
      )
      resolve(data)
    } catch (e) {
      reject(e)
    }
  })
}

export default couponsService
