<template>
  <div v-if="shouldShowFeaturedProducts" class="featured-products">
    <div class="featured-products__title">
      <h2>
        Productos Destacados
      </h2>

      <div
        v-if="shouldShowCarouselNavigationControls"
        class="featured-products__title__carousel-navigation-buttons"
      >
        <button @click="movePagination(-1)">
          <img :src="require('@/assets/icon-arrow-left-featured.svg')" />
        </button>
        <button @click="movePagination(1)">
          <img :src="require('@/assets/icon-arrow-right-featured.svg')" />
        </button>
      </div>
    </div>
    <div class="carousel-wrapper">
      <carousel
        :per-page="isMobile ? 2 : 3"
        :navigate-to="paginationControl"
        :pagination-padding="isMobile ? 3 : 6"
        :mouse-drag="false"
        :autoplay="true"
        :autoplayTimeout="5000"
        :loop="true"
        :pagination-size="24"
        pagination-color="#E2E8F0"
        pagination-active-color="#0F172A"
        @page-change="onAutoplayPageChange"
      >
        <slide v-for="(product, index) in featuredProducts" :key="`featured-${index}`">
          <div class="featured-product">
            <ProductCard :product="product" />
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import ProductCard from '@/components/products/ProductCard'

export default {
  components: {
    ProductCard
  },

  data() {
    return {
      paginationControl: 0
    }
  },

  computed: {
    ...mapState('categories', ['categorieSelected']),
    ...mapGetters('app', ['isMobile']),
    ...mapState('products', ['featuredProducts', 'searchQuery']),
    shouldShowFeaturedProducts() {
      return (
        this.featuredProducts?.length &&
        (!this.categorieSelected || !this.categorieSelected.id) &&
        !this.searchQuery
      )
    },
    itemsPerCarouselPage() {
      return this.isMobile ? 2 : 3
    },
    shouldShowCarouselNavigationControls() {
      return this.featuredProducts.length > this.itemsPerCarouselPage
    }
  },
  created() {
    this.getFeaturedProducts()
  },
  methods: {
    ...mapActions('products', ['getFeaturedProducts']),
    movePagination(moveTo) {
      const maxSlideIndex = Math.floor(this.featuredProducts.length / this.itemsPerCarouselPage)

      this.paginationControl += moveTo

      if (this.paginationControl > maxSlideIndex) {
        this.paginationControl = 0
      } else if (this.paginationControl < 0) {
        this.paginationControl = maxSlideIndex
      }
    },
    onAutoplayPageChange(pageIndex) {
      this.paginationControl = pageIndex
    }
  }
}
</script>

<style lang="scss">
.featured-products {
  .VueCarousel-pagination {
    .VueCarousel-dot-container {
      margin-top: -9px !important;
    }

    .VueCarousel-dot {
      height: 2px !important;
      border-radius: initial;
    }
  }

  .VueCarousel-navigation {
    position: absolute !important;
    right: 0 !important;

    button {
      background: #f8fafc;
      border: 1px solid #e2e8f0;
      border-radius: 100%;
      display: flex;

      img {
        width: 6px !important;
        height: 12px !important;
        margin: 0 !important;
      }
    }
  }

  .VueCarousel-inner {
    align-items: stretch;

    .featured-product,
    .product-card {
      height: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.featured-products {
  position: relative;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #0f172a;
    }

    &__carousel-navigation-buttons {
      display: flex;

      button:first-child {
        margin-right: 16px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #f8fafc;
        border: 1px solid #e2e8f0;
        border-radius: 100%;
        width: 40px;
        height: 40px;
      }
    }
  }
}

.loader-wrapper {
  margin-top: 80px;
}
.more-products {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
  button {
    background: var(--color-tertiary);
    color: #fff;
  }
}

.carousel-wrapper {
  margin-bottom: 34px;
  .featured-product {
    // padding: 0.4rem;
    margin-right: 10px;
  }
}
::v-deep .VueCarousel-navigation-button:focus {
  outline: none !important;
}
</style>
