export const HOURS_TO_DELETE_CART = 12

export const IDENTIFICATION_PERU_DNI_FORMAT_LENGTH = 8

export const IDENTIFICATION_DOM_RNC_FORMAT_LENGTH = 9
export const IDENTIFICATION_DOM_CED_FORMAT_LENGTH = 11

export const IDENTIFICATION_CRI_CF_FORMAT_LENGTH = 9
export const IDENTIFICATION_CRI_CJ_FORMAT_LENGTH = 10
export const IDENTIFICATION_CRI_DIMEX_FORMAT_MIN_LENGTH = 11
export const IDENTIFICATION_CRI_DIMEX_FORMAT_MAX_LENGTH = 12
export const IDENTIFICATION_CRI_NITE_FORMAT_LENGTH = 10
export const IDENTIFICATION_CRI_PE_FORMAT_LENGTH = 20

export const VERSIONS = {
  COLOMBIA: 'colombia',
  REPUBLICA_DOMINICANA: 'republicaDominicana',
  PERU: 'peru',
  ARGENTINA: 'argentina',
  COSTA_RICA: 'costaRica',
  PANAMA: 'panama',
  MEXICO: 'mexico',
  CHILE: 'chile',
  SPAIN: 'spain',
  BOLIVIA: 'bolivia',
  USA: 'usa'
}

export const PRODUCT_TYPES = {
  VARIANT_PARENT: 'variantParent',
  VARIANT: 'variant',
  PRODUCT: 'product'
}

export const ALLOWED_PAYMENT_METHODS = {
  PAYU: 'PAYU',
  MERCADO_PAGO: 'MERCADO_PAGO',
  NEQUI: 'NEQUI',
  VISANET: 'VISANET',
  GREENPAY: 'GREENPAY',
  PAYPAL: 'PAYPAL',
  WHATSAPP: 'WHATSAPP'
}

export const TYPES_PAYMENT_ATTEMPT = {
  FIFTH_ATTEMPT: 5
}
