import store from '@/store'

import couponsService from '@/services/coupons'

const actions = {
  verifyCoupon(context, { promoCode }) {
    const idStore = store.get('shop/shop.id')

    return new Promise(async (resolve, reject) => {
      try {
        const { discountCoupon } = await couponsService.verifyCoupon(idStore, promoCode)

        store.set('coupons/appliedCoupon', discountCoupon)

        resolve(discountCoupon)
      } catch (e) {
        reject(e)
      }
    })
  },
  removeAppliedCoupon() {
    store.set('coupons/appliedCoupon', null)
  }
}

export default actions
