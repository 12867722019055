var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-banner"
  }, [_vm._m(0), _c('p', {
    staticClass: "info-banner__text"
  }, [_vm._v(_vm._s(_vm.text))])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('figure', {
    staticClass: "info-banner__icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-info-lilac.svg"),
      "alt": "Information"
    }
  })]);
}]

export { render, staticRenderFns }