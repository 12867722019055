<template>
  <footer v-if="shouldShowFooter" class="footer" ref="footer">
    <div class="footer-top columns">
      <div v-if="shop.description" class="column description">
        <div class="pre">{{ shop.description }}</div>
      </div>
      <div v-if="shouldShowSocial" class="column social-networks">
        <h3 v-if="!isMobile">SÍGUENOS</h3>
        <app-social-networks />
      </div>
      <div class="column contact">
        <div class="wrapper">
          <template v-if="shouldShowContactInfo">
            <h3 v-if="!isMobile">INFORMACIÓN DE CONTACTO</h3>
            <div class="level">
              <div class="level-item">
                <img src="@/assets/icon-phone.svg" />
                <a :href="`tel:${shop.contactInfo.phone}`">{{ shop.contactInfo.phone }}</a>
              </div>
            </div>
            <div v-if="shouldShowContactInfo" class="level">
              <div class="level-item">
                <img src="@/assets/icon-mail.svg" />
                <a :href="`mailto:${shop.contactInfo.email}`">{{ shop.contactInfo.email }}</a>
              </div>
            </div>
          </template>
          <div v-if="shop.address" class="level">
            <div class="level-item">
              <img src="@/assets/icon-map-pin.svg" />
              <span>{{ shop.address }}</span>
            </div>
          </div>
        </div>
      </div>

      <FooterRegretSection v-if="shouldShowRegretSection" />
    </div>

    <div class="footer-bottom columns">
      <div class="column payments">
        <div v-if="automaticPayments && automaticPayments.length" class="level">
          <div class="level-item">
            <h4>MEDIOS DE PAGO</h4>
          </div>
          <div v-for="(payment, index) in automaticPayments" :key="index" class="level-item">
            <img
              :class="payment.provider.toLowerCase()"
              :src="require(`@/assets/logo-${payment.provider.toLowerCase()}.png`)"
            />
          </div>
        </div>
      </div>
      <div class="column shop-name">
        <div>{{ shop.name }} &copy; {{ new Date().getFullYear() }}</div>
      </div>
      <div class="column brand-alegra">
        <div>Tienda creada con tecnología de</div>
        <a :href="landingAlegraTienda" target="_blank">
          <img class="img" src="@/assets/logo-alegra-footer.svg" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from '@/store'
import { VERSIONS } from '@/utils/constants'

import AppSocialNetworks from '@/components/shared/SocialNetworks'
import FooterRegretSection from '@/components/misc/ArgentinaRegretButton/FooterRegretSection'

const { ARGENTINA } = VERSIONS

export default {
  name: 'AppFooter',
  components: { AppSocialNetworks, FooterRegretSection },
  computed: {
    ...mapState('shop', ['shop']),
    ...mapGetters('shop', ['automaticPayments', 'landingAlegraTienda']),
    ...mapGetters('app', ['isMobile']),
    shouldShowFooter() {
      return !!Object.keys(this.shop || {}).length
    },
    shouldShowSocial() {
      return Object.values(this.shop.socialInfo || {}).some(socialNetwork => socialNetwork)
    },
    shouldShowContactInfo() {
      return Object.values(this.shop.contactInfo || {}).some(contactInfo => contactInfo)
    },
    shouldShowRegretSection() {
      const applicationVersion = store.get('shop/shop@config.applicationVersion')

      return applicationVersion === ARGENTINA
    }
  },
  watch: {
    // Esto es para que el conteido de la pagina no se solape con el footer
    shop: {
      handler() {
        this.$nextTick(() => {
          const footerOffset = this.isMobile ? 150 : 100
          const footer = this.$refs.footer

          if (footer) {
            const footerHeight = Math.max(footer.offsetHeight + footerOffset, 340)
            const section = document.querySelector('main')
            if (section) section.style.paddingBottom = `${footerHeight}px`
          }
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: #fff;
  padding: 0;
  font-size: 12px;
  color: #8592ad;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
}
.pre {
  white-space: pre-wrap;
  font-size: 12px;
  color: #8592ad;
  overflow-y: auto;
}
.footer-top {
  height: 197px;
  margin: 0 !important;
  padding: 0 25px 0 35px;
  h3 {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    color: #47536b;
  }
  .column {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  .description {
    align-items: flex-start;
    h3 {
      margin-bottom: 10px;
    }
  }
  .social-networks {
    align-items: center;
    h3 {
      margin-bottom: 32px;
    }
  }
  .contact {
    align-items: flex-start;
    font-size: 14px;
    font-weight: 500;
    .wrapper {
      margin: 0 auto;
    }
    h3 {
      margin-bottom: 10px;
    }
    img {
      margin-right: 14px;
      width: 17px;
      height: 17px;
    }
    a {
      color: #8592ad;
    }
    .level {
      margin-bottom: 16px;
    }
    .level-item {
      justify-content: flex-start;
    }
  }
}
.footer-bottom {
  height: 65px;
  margin: 0 !important;
  padding: 0 25px 0 35px;
  border-top: 1px solid #ededed;
  .column {
    display: flex;
    align-items: center;
  }
  .payments {
    justify-content: flex-start;
    h4 {
      margin-right: 18px;
      color: #47536b;
      font-weight: 500;
    }
    img {
      width: 35px;
      margin: 0 4px;
    }
    .nequi {
      width: 22px;
    }
    .whatsapp {
      width: 23px;
    }
    .payu {
      width: 30px;
      margin: 0 1px;
    }
    .mercado_pago {
      width: 28px;
    }
    .visanet {
      width: 50px;
    }
    .greenpay {
      width: 40px;
    }
  }
  .shop-name {
    justify-content: center;
  }
  .brand-alegra {
    justify-content: flex-end;
    .img {
      margin-left: 9px;
    }
  }
}
@media (max-width: 768px) {
  .level {
    flex-direction: column;
    padding: 10px 2px;
  }
  pre {
    text-align: center;
  }
  .footer-top,
  .footer-bottom {
    height: auto;
  }
  .footer-top {
    .description {
      padding: 0;
    }
    .contact {
      .level {
        padding: 0;
      }
      .wrapper {
        margin-left: 0;
        display: flex;
        flex-direction: inherit;
        justify-content: center;
        width: 100%;
      }
    }
  }
  .footer-bottom {
    .payments {
      justify-content: center;
      .level {
        flex-direction: inherit;
      }
      .level-item {
        margin-bottom: 0;
      }
    }
  }
}
</style>
