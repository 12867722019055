var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "campaigns"
  }, [_c('carousel', {
    attrs: {
      "per-page": 1,
      "mouse-drag": true,
      "autoplay": "",
      "autoplay-timeout": 3000,
      "loop": "",
      "pagination-color": "#E2E8F0",
      "pagination-active-color": "#0F172A",
      "pagination-size": 24,
      "pagination-padding": 8
    }
  }, _vm._l(_vm.activeCampaigns, function (campaign) {
    return _c('slide', {
      key: campaign.id
    }, [_c('div', [_c('a', {
      attrs: {
        "href": campaign.url,
        "target": "_blank"
      }
    }, [_c('img', {
      attrs: {
        "src": campaign.imageUrlDesktop,
        "alt": campaign.name
      }
    })])])]);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }