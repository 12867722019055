var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "no-stock-banner"
  }, [_c('div', {
    staticClass: "no-stock-banner__icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-warning-triangle.svg")
    }
  })]), _c('div', [_c('h3', {
    staticClass: "no-stock-banner__heading"
  }, [_vm._v(" Producto no disponible o agotado ")]), _c('p', {
    staticClass: "no-stock-banner__description"
  }, [_vm._v(" Uno o varios de los productos añadidos al carrito no están disponibles en este momento, por este motivo no es posible finalizar la compra. ")])])]);
}]

export { render, staticRenderFns }