import store from '@/store'

import campaignsService from '@/services/campaigns'

const actions = {
  getActiveCampaigns(context) {
    const idStore = store.get('shop/shop.id')

    return new Promise(async (resolve, reject) => {
      try {
        const { banners } = await campaignsService.getActiveCampaigns(idStore)

        const bannersWithImages = banners.filter(
          banner => banner.imageUrlDesktop || banner.imageUrlMobil
        )

        store.set('campaigns/activeCampaigns', bannersWithImages)

        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }
}

export default actions
