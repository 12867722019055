<script>
export default {
  name: 'app-info-modal',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  emits: ['toggle-modal']
}
</script>

<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-content">
      <h1 class="modal-content__title">{{ title }}</h1>
      <p class="modal-content__description">{{ description }}</p>
      <button
        class="button modal-content__button"
        @click="$emit('toggle-modal')"
      >
        CERRAR
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-content {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background-color: var(--color-primary);

  &__title,
  &__description {
    color: var(--color-text);
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
  }

  &__button {
    border: 1px solid var(--color-tertiary);
    color: var(--color-light);
    background: var(--color-tertiary);

    &:hover {
      color: var(--color-light);
    }
  }
}
</style>
