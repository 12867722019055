<template>
  <section class="section">
    <banner-has-no-iva-days />
    <campaigns-banner v-if="shouldShowBanners" />
    <featured-products />
    <app-products />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import AppProducts from '@/components/products/Products.vue'
import BannerHasNoIvaDays from '@/components/layout/BannerHasNoIvaDays.vue'
import FeaturedProducts from '../components/products/FeaturedProducts.vue'
import CampaignsBanner from '@/components/layout/CampaignsBanner'

export default {
  components: {
    AppProducts,
    BannerHasNoIvaDays,
    FeaturedProducts,
    CampaignsBanner
  },
  metaInfo() {
    const title = 'Tienda'
    const description = '¡Visita nuestra tienda online!'
    // let title = this.shop.name
    // if (this.$route.params.category) {
    //   let category = this.$route.params.category.split('-')
    //   category = category.slice(0, category.length - 1).join(' ') + ' - '
    //   title = category + title
    // }
    return {
      title,
      meta: [
        { name: 'title', content: title },
        { property: 'og:title', content: title },
        { name: 'description', content: description },
        { property: 'og:description', content: description },
        { property: 'og:site_name', content: title },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://${this.shop.domain}${this.$route.path}` },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  },
  computed: {
    ...mapState('shop', ['shop']),
    shouldShowBanners() {
      return this.$route.name === 'home'
    }
  }
}
</script>
