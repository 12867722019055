<template>
  <modal
    name="regret-form-modal"
    class="regret-form-modal"
    adaptive
    scrollable
    focusTrap
    :max-width="480"
    width="100%"
    height="auto"
  >
    <div v-if="isLoading" class="loading-container">
      <Loading loading />
    </div>
    <div v-else class="regret-form-container">
      <div class="regret-description">
        <h4>Solicitud de arrepentimiento</h4>

        <p>
          Según la <strong>Resolución 424/2020</strong>, tenés 10 días desde la realización de tu
          compra para solicitar la cancelación.
        </p>

        <p>Ingresá la siguiente información para continuar con tu solicitud.</p>
      </div>
      <form @submit.prevent="submitForm" class="regret-form">
        <div class="form-input">
          <label for="orderNumber">
            Número de pedido
            <!-- <img src="@/assets/icon-info.svg" alt="Icono de ayuda" /> -->
          </label>
          <input
            v-model.trim="orderNumber"
            type="text"
            id="orderNumber"
            placeholder="Ingresá el número de tu pedido..."
          />
          <span v-if="errors.orderNumber">
            {{ errorMessages.orderNumber }}
          </span>
        </div>
        <div class="form-input">
          <label for="email"> Correo electrónico </label>
          <input
            v-model.trim="email"
            type="email"
            id="email"
            placeholder="Ingresá el correo con el que realizaste la compra..."
          />
          <span v-if="errors.email">
            {{ errorMessages.email }}
          </span>
        </div>
        <div class="form-input">
          <label for="emailConfirm"> Confirmá el correo electrónico </label>
          <input
            v-model.trim="emailConfirm"
            type="email"
            id="emailConfirm"
            placeholder="Confirma el correo electrónico..."
          />
          <span v-if="errors.emailConfirm">
            {{ errorMessages.emailConfirm }}
          </span>
        </div>

        <vue-recaptcha
          ref="recaptcha"
          :sitekey="recaptchaSiteKey"
          @verify="onCaptchaVerified"
          size="invisible"
        ></vue-recaptcha>

        <div class="form-buttons">
          <button class="button-outline" @click="$modal.hide('regret-form-modal')">Cancelar</button>
          <button class="button-primary">Enviar</button>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import { mapActions } from 'vuex'
import Loading from '@/components/shared/Loading'

export default {
  name: 'RegretFormModal',
  components: {
    VueRecaptcha,
    Loading
  },
  data() {
    return {
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      isLoading: false,
      orderNumber: '',
      email: '',
      emailConfirm: '',
      errorMessages: {
        orderNumber: 'Debes ingresar el número de pedido',
        email: 'Debes ingresar el correo electrónico con el que realizaste la compra',
        emailConfirm: 'Debes confirmar el correo electrónico'
      },
      errors: {
        orderNumber: false,
        email: false,
        emailConfirm: false
      }
    }
  },
  methods: {
    ...mapActions('order', ['regretOrder']),
    async submitForm() {
      if (!this.$refs.recaptcha) {
        return false
      }

      await this.$refs.recaptcha.execute()
    },
    onCaptchaVerified(response) {
      if (response) {
        this.processForm()
      } else {
        // TODO: Implementar lógica de error
      }
    },
    async processForm() {
      if (!this.orderNumber) {
        this.errors.orderNumber = true
      }

      if (!this.email) {
        this.errors.email = true
      }

      if (!this.emailConfirm) {
        this.errors.emailConfirm = true
      }

      if (this.email && this.emailConfirm && this.email !== this.emailConfirm) {
        this.errorMessages.emailConfirm = 'Los correos electrónicos no coinciden'
        this.errors.emailConfirm = true
      }

      await this.$refs.recaptcha.reset()

      if (Object.values(this.errors).some(error => error)) {
        return false
      }

      this.isLoading = true

      await this.regretOrder({
        orderNumber: this.orderNumber,
        email: this.email
      })

      this.$modal.hide('regret-form-modal')
      this.$modal.show('regret-success-modal')
      this.isLoading = false
    }
  },
  watch: {
    orderNumber() {
      if (this.orderNumber) {
        this.errors.orderNumber = false
      }
    },
    email() {
      if (this.email) {
        this.errors.email = false
      }
    },
    emailConfirm() {
      if (this.emailConfirm) {
        this.errors.emailConfirm = false
      }
    }
  }
}
</script>
<style lang="scss">
.regret-form-modal {
  .vm--modal {
    border-radius: 16px;
  }
}
</style>

<style lang="scss" scoped>
.loading-container {
  min-height: 500px;
  display: grid;
  place-items: center;
}

.regret-form-container {
  padding: 16px;
  height: 100%;
}

.regret-description {
  h4 {
    color: #0f172a;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 3px;
  }

  p {
    color: #334155;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

.regret-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .form-input {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      color: #334155;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      img {
        margin-left: 4px;
        cursor: pointer;
        width: 18px;
        height: 18px;
      }
    }

    input {
      border-radius: 10px;
      border: 1px solid #cbd5e1;
      font-size: 16px;
      color: #334155;
      padding: 10px;

      &::placeholder {
        color: #94a3b8;
      }
    }

    span {
      color: #e63f3f;
    }
  }

  .form-buttons {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    button {
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding: 7px 20px;
      cursor: pointer;
    }

    .button-outline {
      border: 1px solid #cbd5e1;
      color: #0f172a;
      background: transparent;

      &:hover {
        background: rgba(226, 232, 240, 0.45);
      }
    }

    .button-primary {
      background-color: #30aba9;
      color: #fff;
      border: none;

      &:hover {
        background: #299e9c;
      }
    }
  }
}
</style>
