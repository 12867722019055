import { make } from 'vuex-pathify'
import actions from './actions'

const inistialState = () => ({
  showNotification: false,
  textNotification: '',
  classNotification: ''
})

const state = inistialState()

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
