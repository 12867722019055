import store from '@/store'

const actions = {
  showDangerNotification(context, { text }) {
    store.set('notification/classNotification', 'is-danger is-light')
    store.set('notification/textNotification', text)
    store.set('notification/showNotification', true)
    setTimeout(() => {
      store.set('notification/showNotification', false)
    }, 8000)
  }
}

export default actions
