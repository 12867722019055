var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shop ? _c('div', {
    staticClass: "level-item level-item-left social-icons"
  }, [_vm.shop.socialInfo && _vm.shop.socialInfo.facebook ? _c('img', {
    staticClass: "pointer",
    attrs: {
      "src": require("@/assets/icon-facebook.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.openSocialNetwork('facebook');
      }
    }
  }) : _vm._e(), _vm.shop.socialInfo && _vm.shop.socialInfo.instagram ? _c('img', {
    staticClass: "pointer",
    attrs: {
      "src": require("@/assets/icon-instagram.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.openSocialNetwork('instagram');
      }
    }
  }) : _vm._e(), _vm.shop.socialInfo && _vm.shop.socialInfo.twitter ? _c('img', {
    staticClass: "pointer",
    attrs: {
      "src": require("@/assets/icon-social-x.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.openSocialNetwork('twitter');
      }
    }
  }) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }