const urlUtil = {}

urlUtil.formatSeoUrl = (value) => {
  let url = ''
  if (value) {
    url = value.replace(/[^a-zA-Z 0-9 \u00E0-\u00FC \u00C0-\u00DC]+/g, '').replace(/ /g, '-').toLowerCase()
  }
  return url
}

urlUtil.getQueryParams = name => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(name)
}

export default urlUtil
