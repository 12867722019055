var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.addedProductModalIsOpen && _vm.product ? _c('div', {
    staticClass: "modal is-active"
  }, [_c('div', {
    staticClass: "modal-background"
  }), _c('div', {
    staticClass: "modal-content"
  }, [_c('h1', [_vm._v("Tu producto se agregó al carrito")]), _c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column is-3 product-img"
  }, [_vm.product.mainImage ? _c('img', {
    attrs: {
      "src": _vm.product.mainImage
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icon-item-default.svg")
    }
  })]), _c('div', {
    staticClass: "column is-9"
  }, [_c('h2', [_vm._v(_vm._s(_vm.product.name))]), _vm.productQuantity ? _c('p', {
    staticClass: "quantity"
  }, [_vm._v("Cantidad: " + _vm._s(_vm.productQuantity))]) : _vm._e(), _c('p', [_vm._v("Precio por unidad")]), _c('p', {
    staticClass: "price"
  }, [_vm._v(_vm._s(_vm.$currencyFormatter(_vm.product.finalPrice)))])])]), _c('button', {
    staticClass: "button is-fullwidth go-cart",
    on: {
      "click": function ($event) {
        return _vm.goToShoppingCart();
      }
    }
  }, [_vm._v("IR AL CARRITO")]), _c('button', {
    staticClass: "button is-fullwidth close",
    on: {
      "click": function ($event) {
        return _vm.closeModal(true);
      }
    }
  }, [_vm._v("SEGUIR COMPRANDO")])]), _c('button', {
    staticClass: "modal-close is-large",
    attrs: {
      "aria-label": "close"
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  })]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }