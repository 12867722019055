<template>
  <div v-if="loading">
    <div class="loader-wrapper is-active" :class="size">
      <div class="loader is-warning is-loading" />
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    size: { type: String, required: false, default: '' }
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: -1;
  transition: opacity 5.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 14px;

  .loader {
    height: 80px;
    width: 80px;
    border: 7px solid #334155;
    border-top-color: transparent;
  }

  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}

.loader-wrapper.small {
  .loader {
    height: 15px;
    width: 15px;
    border: 1px solid #334155;
    border-top-color: transparent;
  }
}

.loader-wrapper.medium {
  .loader {
    height: 40px;
    width: 40px;
    border: 1px solid #334155;
    border-top-color: transparent;
  }
}
</style>
