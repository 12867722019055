<template>
  <Header>
    <div class="level">
      <div class="level-left">
        <a href="/" class="link-logo" v-if="shop">
          <img v-if="shopLogo" class="image image-logo" :src="shopLogo" />
          <h2 v-else class="title-shop">{{ shop.name }}</h2>
        </a>
      </div>
      <div class="level-center">
        <div class="level-item level-search">
          <app-input-search />
        </div>
      </div>
      <div class="level-right">
        <div class="level-item level-shopping-cart">
          <span>MI CARRITO</span>
          <div class="shopping-cart pointer" @click="goToShoppingCart">
            <inline-svg :src="require('@/assets/icon-shopping-car.svg')" />
            <div v-if="productsInCart.length" class="badge has-text-centered">
              <p>{{ unitsInCart }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppInputSearch from '@/components/shared/InputSearch'

export default {
  components: {
    AppInputSearch
  },
  computed: {
    ...mapState('cart', ['productsInCart']),
    ...mapState('shop', ['shop']),
    ...mapGetters('cart', ['unitsInCart']),
    ...mapGetters('shop', ['shopLogo'])
  },
  methods: {
    goToShoppingCart() {
      // TODO: Hay un bug que no deja actualizar cantidad del stock en el carrito sin recargar la pagina
      // this.$router.push('/shopping-cart')
      window.location.pathname = '/shopping-cart'
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  background-color: var(--color-secondary);
  position: fixed;
  width: 100%;
  padding: 0 15%;
  z-index: 5;
  height: 76px;
  border-bottom: 1px solid var(--color-border);
  .level {
    height: 100%;
    .level-item {
      width: 100%;
    }
    .level-left {
      width: 20%;
      max-width: 20%;
      .link-logo {
        max-width: 100%;
        .image-logo {
          min-height: 40px;
          max-height: 58px;
          max-width: 100%;
        }
        .title-shop {
          text-transform: uppercase;
          font-size: 18px;
          color: var(--color-text);
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .level-center {
      display: flex;
      justify-content: center;
      width: 60%;
      .level-search {
        .field {
          width: 100%;
          padding: 0 10px;
        }
      }
    }
    .level-right {
      width: 20%;
      .level-shopping-cart {
        justify-content: flex-end;
        span {
          color: var(--color-text);
          font-weight: 500;
          font-size: 12px;
          line-height: 21px;
          margin-right: 16px;
        }
        .shopping-cart {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          background: var(--color-cart);
          box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
          border-radius: 22px;
          svg {
            stroke: var(--color-icon);
          }
          .badge {
            position: absolute;
            top: 23px;
            right: -7px;
            width: 27.31px;
            height: 27.31px;
            background: var(--color-contrast);
            color: #fff;
            font-size: 15px;
            padding-top: 3px;
            box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 22px;
          }
        }
      }
    }
  }
}
</style>
