<template>
  <transition name="notification-fade">
    <div
      v-if="showNotification"
      class="notification"
      :class="classNotification"
    >
      <button class="delete" @click="close"></button>
      {{ textNotification }}
    </div>
  </transition>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('notification', ['showNotification', 'textNotification', 'classNotification'])
  },
  methods: {
    close() {
      store.set('notification/showNotification', false)
    }
  }
}
</script>

<style lang="scss">
  .notification {
    position: fixed !important;
    z-index: 999;
    min-width: 30%;
    max-width: 40%;
    right: 2%;
    top: 20px;
  }
  /* Las animaciones */
  .notification-fade-enter-active {
    transition: all .5s ease;
  }
  .notification-fade-leave-active {
    transition: all .6s ease;
  }
  .notification-fade-enter, .notification-fade-leave-to
  {
    transform: translateX(+100%);
    opacity: 0.7;
  }
  /* Fin de Las animaciones */

  @media (max-width: 768px) {
    .notification {
      min-width: 90%;
      max-width: 96%;
    }
  }
</style>
