var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "paypal-payment"
  }, [_vm.isLoadingPaypal ? _c('Loading', {
    staticClass: "paypal-payment__loading",
    attrs: {
      "loading": ""
    }
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoadingPaypal,
      expression: "!isLoadingPaypal"
    }]
  }, [_vm._m(0), _c('div', {
    attrs: {
      "id": "paypal-buttons"
    }
  }), _vm.isLoadingPaypalCreditCardForm ? _c('div', {
    staticClass: "paypal-payment__form-loading"
  }, [_c('Loading', {
    attrs: {
      "loading": ""
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "paypal-payment__logo"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/logo-paypal.png"),
      "alt": "Paypal logo"
    }
  })]);
}]

export { render, staticRenderFns }