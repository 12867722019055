import { make } from 'vuex-pathify'
import gettersCustom from './getters'

const inistialState = () => ({
  showMenuMobile: false,
  windowWidth: null
})

const state = inistialState()

const getters = {
  ...gettersCustom,
  ...make.getters(state)
}

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
