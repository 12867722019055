var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shouldShowFeaturedProducts ? _c('div', {
    staticClass: "featured-products"
  }, [_c('div', {
    staticClass: "featured-products__title"
  }, [_c('h2', [_vm._v(" Productos Destacados ")]), _vm.shouldShowCarouselNavigationControls ? _c('div', {
    staticClass: "featured-products__title__carousel-navigation-buttons"
  }, [_c('button', {
    on: {
      "click": function ($event) {
        return _vm.movePagination(-1);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/icon-arrow-left-featured.svg')
    }
  })]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.movePagination(1);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/icon-arrow-right-featured.svg')
    }
  })])]) : _vm._e()]), _c('div', {
    staticClass: "carousel-wrapper"
  }, [_c('carousel', {
    attrs: {
      "per-page": _vm.isMobile ? 2 : 3,
      "navigate-to": _vm.paginationControl,
      "pagination-padding": _vm.isMobile ? 3 : 6,
      "mouse-drag": false,
      "autoplay": true,
      "autoplayTimeout": 5000,
      "loop": true,
      "pagination-size": 24,
      "pagination-color": "#E2E8F0",
      "pagination-active-color": "#0F172A"
    },
    on: {
      "page-change": _vm.onAutoplayPageChange
    }
  }, _vm._l(_vm.featuredProducts, function (product, index) {
    return _c('slide', {
      key: ("featured-" + index)
    }, [_c('div', {
      staticClass: "featured-product"
    }, [_c('ProductCard', {
      attrs: {
        "product": product
      }
    })], 1)]);
  }), 1)], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }