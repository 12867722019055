<template>
  <div class="applied-coupon">
    <span class="applied-coupon__promo-code">
      <img :src="require('@/assets/icon-coupon.svg')" alt="Icono de descuento" />
      {{ appliedCoupon.code }}
    </span>
    <span class="applied-coupon__discount">- {{ appliedCoupon.discountPercentage }}%</span>
    <div
      v-if="shouldShowRemoveCouponButton"
      class="applied-coupon__close"
      @click="$emit('remove-coupon')"
    >
      <img :src="require('@/assets/icon-close.svg')" alt="Icono de cerrar" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppliedCoupon',
  props: {
    appliedCoupon: {
      type: Object,
      required: true
    },
    currentStep: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('cart', ['appliedCouponDiscountedPrice']),
    shouldShowRemoveCouponButton() {
      const hideRemoveCouponButtonSteps = ['CONFIRM_SUCCESS']

      if (hideRemoveCouponButtonSteps.includes(this.currentStep)) return false

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.applied-coupon {
  background: #fafcff;
  color: #4a90e2;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 14px 35px 14px 12px;
  position: relative;

  &__promo-code {
    display: flex;
    align-items: center;
    font-size: 12px;

    img {
      margin-top: -3px;
      margin-right: 7px;
    }
  }

  &__discount {
    font-size: 14px;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    display: grid;
    place-items: center;
    background: white;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 100%;
    padding: 7px;
    cursor: pointer;
    z-index: 10;
    transform: translate(-50%, -50%);

    img {
      height: 12px;
      width: 10px;
    }
  }
}
</style>
