<template>
  <div class="coupon-section">
    <CouponInput
      v-if="!appliedCoupon"
      :loading="loading"
      :coupon-error="couponError"
      @input-submit="inputSubmit"
    />

    <AppliedCoupon
      v-else
      :applied-coupon="appliedCoupon"
      :current-step="currentStep"
      @remove-coupon="removeCoupon"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CouponInput from './CouponInput'
import AppliedCoupon from './AppliedCoupon'

export default {
  name: 'CouponSection',
  components: {
    CouponInput,
    AppliedCoupon
  },
  props: {
    currentStep: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      couponError: false
    }
  },
  computed: {
    ...mapState('coupons', ['appliedCoupon'])
  },
  methods: {
    ...mapActions('coupons', ['verifyCoupon', 'removeAppliedCoupon']),
    async inputSubmit(promoCode) {
      this.loading = true
      this.couponError = false

      try {
        await this.verifyCoupon({ promoCode })
      } catch (e) {
        this.couponError = true
      }
      this.loading = false
    },
    removeCoupon() {
      this.removeAppliedCoupon()
    }
  }
}
</script>

<style lang="scss" scoped></style>
