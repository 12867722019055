import currencyUtil from '@/utils/currency'

const currency = {}

currency.install = function(Vue) {
  Vue.filter('currency', val => {
    return currencyUtil.convertcurrency(val)
  })
}

export default currency
