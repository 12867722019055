var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content",
    style: {
      backgroundImage: ("url(" + (require("@/assets/background-products.jpg")) + ")")
    }
  }, [_c('div', {
    staticClass: "container-main"
  }, [_c('div', {
    staticClass: "brand-tienda link",
    on: {
      "click": function ($event) {
        return _vm.goLanding();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-shop-brand.svg")
    }
  }), _vm._v(" ALEGRA TIENDA ")]), _c('h1', [_vm._v("La tienda "), _vm.shop && _vm.shop.name ? _c('span', [_vm._v(_vm._s(_vm.shop.name))]) : _vm._e(), _vm._v(" se encuentra inactiva en este momento ")]), _c('p', [_vm._v(_vm._s(_vm.contactText))]), _vm._m(0)]), _vm._m(1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-register"
  }, [_vm._v(" ¿Quieres tener tu propia tienda en línea? "), _c('a', {
    attrs: {
      "href": "https://www.alegra.com/tienda"
    }
  }, [_vm._v("Regístrate ahora")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-footer"
  }, [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column desktop"
  }, [_c('a', {
    staticClass: "option-footer",
    attrs: {
      "href": "https://www.alegra.com/tienda",
      "target": "_blank"
    }
  }, [_vm._v("Alegra Tienda")]), _c('a', {
    staticClass: "option-footer",
    attrs: {
      "href": "https://ayuda.alegra.com/es/configura-tu-tienda-en-l%C3%ADnea-y-comienza-a-vender-con-alegra-tienda",
      "target": "_blank"
    }
  }, [_vm._v("Ayuda")]), _c('a', {
    staticClass: "option-footer",
    attrs: {
      "href": "https://www.alegra.com/tienda/#empresas",
      "target": "_blank"
    }
  }, [_vm._v("Tiendas")])]), _c('div', {
    staticClass: "column your-shop-footer"
  }, [_c('a', {
    attrs: {
      "href": "https://tienda.alegra.com/home"
    }
  }, [_vm._v("¿Es tu tienda? "), _c('span', [_vm._v("Actívate ahora")])])])])]);
}]

export { render, staticRenderFns }