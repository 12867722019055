var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "coupon-input",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitInput.apply(null, arguments);
      }
    }
  }, [_c('label', {
    attrs: {
      "for": "couponInput"
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/icon-coupon.svg'),
      "alt": "Icono de descuento"
    }
  }), _vm._v(" CUPÓN ")]), _c('div', {
    staticClass: "coupon-input__field",
    class: {
      'coupon-input__field--error': _vm.couponError && !_vm.removeError
    }
  }, [_c('div', {
    staticClass: "coupon-input__field__input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.promoCode,
      expression: "promoCode",
      modifiers: {
        "trim": true
      }
    }],
    attrs: {
      "id": "couponInput",
      "type": "text",
      "placeholder": "Escribe el codigo de tu cupón"
    },
    domProps: {
      "value": _vm.promoCode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.promoCode = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.couponError && !_vm.removeError ? _c('span', {
    staticClass: "coupon-input__field__error-hint"
  }, [_vm._v(" Código no válido ")]) : _vm._e()]), _c('button', {
    staticClass: "button",
    class: {
      'is-loading': _vm.loading
    },
    attrs: {
      "type": "submit",
      "disabled": _vm.couponError && !_vm.removeError
    }
  }, [_vm._v(" Aplicar ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }