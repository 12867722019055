var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "notification-fade"
    }
  }, [_vm.showNotification ? _c('div', {
    staticClass: "notification",
    class: _vm.classNotification
  }, [_c('button', {
    staticClass: "delete",
    on: {
      "click": _vm.close
    }
  }), _vm._v(" " + _vm._s(_vm.textNotification) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }