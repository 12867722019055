import apiAlegraStoresService from './api-alegra-stores'

const productService = {}

productService.getById = (idStore, idItem) => {
  const extraAttrs = 'variantAttributes,itemVariants'
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await apiAlegraStoresService.get(
        `/stores/${idStore}/items/${idItem}?extraAttrs=${extraAttrs}`
      )
      resolve(data)
    } catch (e) {
      reject(e)
    }
  })
}

productService.getInventoryAvailability = (idStore, itemIDs) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await apiAlegraStoresService.get(
        `/stores/${idStore}/items/inventory-availability?idItems=${itemIDs}`
      )
      resolve(data)
    } catch (e) {
      reject(e)
    }
  })
}

productService.getProducts = (idStore, { start, limit, idCategory, query, metadata, featured }) => {
  const params = {}
  let url = `/stores/${idStore}/items/load-more`

  if (metadata && metadata.lastKey) {
    params.lastKey = metadata.lastKey
  } else {
    params.start = start
    params.limit = limit
  }

  if (query) {
    url = `/stores/${idStore}/items/search`
    params.query = query
  } else {
    if (idCategory) {
      params.idCategory = idCategory
    }
  }

  if (featured) {
    params.featured = true
  }

  return apiAlegraStoresService
    .get(url, { params })
    .then(res => res.data)
    .catch(e => e)
}

export default productService
