import Vue from 'vue'
import Vuex from 'vuex'

import pathify from 'vuex-pathify'

import products from './products'
import categories from './categories'
import shop from './shop'
import cart from './cart'
import order from './order'
import app from './app'
import modal from './modal'
import notification from './notification'
import config from './config'
import shipping from './shipping'
import coupons from './coupons'
import campaigns from './campaigns'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [pathify.plugin],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    shop,
    products,
    categories,
    cart,
    order,
    app,
    modal,
    notification,
    config,
    shipping,
    coupons,
    campaigns
  }
})

export default store
