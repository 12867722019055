import { make } from 'vuex-pathify'
import actions from './actions'

const initialState = () => ({
  lastOrder: {},
  openedExternalPayment: false,
  validatingPayment: false,
  idCancelRequest: null
})

const state = initialState()

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
