var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shouldShowFooter ? _c('footer', {
    ref: "footer",
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-top columns"
  }, [_vm.shop.description ? _c('div', {
    staticClass: "column description"
  }, [_c('div', {
    staticClass: "pre"
  }, [_vm._v(_vm._s(_vm.shop.description))])]) : _vm._e(), _vm.shouldShowSocial ? _c('div', {
    staticClass: "column social-networks"
  }, [!_vm.isMobile ? _c('h3', [_vm._v("SÍGUENOS")]) : _vm._e(), _c('app-social-networks')], 1) : _vm._e(), _c('div', {
    staticClass: "column contact"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_vm.shouldShowContactInfo ? [!_vm.isMobile ? _c('h3', [_vm._v("INFORMACIÓN DE CONTACTO")]) : _vm._e(), _c('div', {
    staticClass: "level"
  }, [_c('div', {
    staticClass: "level-item"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-phone.svg")
    }
  }), _c('a', {
    attrs: {
      "href": ("tel:" + (_vm.shop.contactInfo.phone))
    }
  }, [_vm._v(_vm._s(_vm.shop.contactInfo.phone))])])]), _vm.shouldShowContactInfo ? _c('div', {
    staticClass: "level"
  }, [_c('div', {
    staticClass: "level-item"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-mail.svg")
    }
  }), _c('a', {
    attrs: {
      "href": ("mailto:" + (_vm.shop.contactInfo.email))
    }
  }, [_vm._v(_vm._s(_vm.shop.contactInfo.email))])])]) : _vm._e()] : _vm._e(), _vm.shop.address ? _c('div', {
    staticClass: "level"
  }, [_c('div', {
    staticClass: "level-item"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-map-pin.svg")
    }
  }), _c('span', [_vm._v(_vm._s(_vm.shop.address))])])]) : _vm._e()], 2)]), _vm.shouldShowRegretSection ? _c('FooterRegretSection') : _vm._e()], 1), _c('div', {
    staticClass: "footer-bottom columns"
  }, [_c('div', {
    staticClass: "column payments"
  }, [_vm.automaticPayments && _vm.automaticPayments.length ? _c('div', {
    staticClass: "level"
  }, [_vm._m(0), _vm._l(_vm.automaticPayments, function (payment, index) {
    return _c('div', {
      key: index,
      staticClass: "level-item"
    }, [_c('img', {
      class: payment.provider.toLowerCase(),
      attrs: {
        "src": require(("@/assets/logo-" + (payment.provider.toLowerCase()) + ".png"))
      }
    })]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "column shop-name"
  }, [_c('div', [_vm._v(_vm._s(_vm.shop.name) + " © " + _vm._s(new Date().getFullYear()))])]), _c('div', {
    staticClass: "column brand-alegra"
  }, [_c('div', [_vm._v("Tienda creada con tecnología de")]), _c('a', {
    attrs: {
      "href": _vm.landingAlegraTienda,
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img",
    attrs: {
      "src": require("@/assets/logo-alegra-footer.svg")
    }
  })])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "level-item"
  }, [_c('h4', [_vm._v("MEDIOS DE PAGO")])]);
}]

export { render, staticRenderFns }