var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bar",
    class: this.status.toLowerCase()
  }), _c('div', {
    staticClass: "container-checkout"
  }, [_c('div', {
    staticClass: "container-icon",
    class: this.status.toLowerCase()
  }, [_c('img', {
    staticClass: "icon-success",
    attrs: {
      "src": require(("@/assets/" + (_vm.statusIcon)))
    }
  }), _c('h3', {
    staticClass: "status-text",
    class: this.status.toLowerCase()
  }, [_vm._v(_vm._s(_vm.statusText))])]), _c('h4', {
    staticClass: "number-order-text"
  }, [_vm._v("NÚMERO DE PEDIDO "), _c('strong', [_vm._v(_vm._s(_vm.lastOrder.number))])]), this.status == 'APPROVED' ? _c('div', {
    staticClass: "container-secondary-text"
  }, [_c('p', {
    staticClass: "secondary-text"
  }, [_vm._v("Hemos enviado el comprobante de pago al correo")]), _c('p', {
    staticClass: "secondary-text"
  }, [_vm._v(_vm._s(_vm.lastOrder.client.email))])]) : _vm._e(), this.status == 'PENDING' ? _c('div', {
    staticClass: "container-secondary-text"
  }, [_c('p', {
    staticClass: "secondary-text"
  }, [_vm._v("Enviaremos el comprobante de pago al correo")]), _c('p', {
    staticClass: "secondary-text"
  }, [_vm._v(_vm._s(_vm.lastOrder.client.email))])]) : _vm._e(), this.status == 'REJECTED' ? _c('div', {
    staticClass: "container-secondary-text"
  }, [_c('p', {
    staticClass: "secondary-text"
  }, [_vm._v("Ha ocurrido un error al intentar realizar el pago de tu pedido,")]), _c('p', {
    staticClass: "secondary-text"
  }, [_c('strong', [_vm._v(_vm._s(_vm.statusCause))])]), _c('p', {
    staticClass: "secondary-text"
  }, [_vm._v("te recomendamos intentarlo nuevamente o ponerte")]), _c('p', {
    staticClass: "secondary-text"
  }, [_vm._v("en contacto al teléfono " + _vm._s(_vm.shop.contactInfo.phone))])]) : _vm._e(), this.status == 'FAILED' ? _c('div', {
    staticClass: "container-secondary-text"
  }, [_c('p', {
    staticClass: "secondary-text"
  }, [_vm._v("Ha ocurrido un error al intentar crear el pago de tu pedido,")]), _c('p', {
    staticClass: "secondary-text"
  }, [_vm._v("te recomendamos intentarlo nuevamente o ponerte")]), _c('p', {
    staticClass: "secondary-text"
  }, [_vm._v("en contacto al teléfono " + _vm._s(_vm.shop.contactInfo.phone))])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }