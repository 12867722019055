var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "coupon-section"
  }, [!_vm.appliedCoupon ? _c('CouponInput', {
    attrs: {
      "loading": _vm.loading,
      "coupon-error": _vm.couponError
    },
    on: {
      "input-submit": _vm.inputSubmit
    }
  }) : _c('AppliedCoupon', {
    attrs: {
      "applied-coupon": _vm.appliedCoupon,
      "current-step": _vm.currentStep
    },
    on: {
      "remove-coupon": _vm.removeCoupon
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }