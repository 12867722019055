var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cart-product"
  }, [_c('div', {
    staticClass: "cart-product__image-name"
  }, [_c('div', {
    staticClass: "cart-product__image"
  }, [_vm.product.mainImage ? _c('img', {
    attrs: {
      "src": _vm.product.mainImage
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icon-item-default.svg")
    }
  })]), _c('div', {
    staticClass: "cart-product__name"
  }, [_vm.product.hasNoIvaDays ? _c('p', {
    staticClass: "label-no-iva-day"
  }, [_vm._v("Sin IVA")]) : _vm._e(), _c('router-link', {
    attrs: {
      "to": _vm.productPageRoute
    }
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " ")])], 1)]), _c('div', {
    staticClass: "cart-product__quantity"
  }, [_c('AppMoreOrLessProduct', {
    attrs: {
      "quantity": _vm.product.quantity,
      "max-quantity": _vm.stockQuantity,
      "sell-without-stock": _vm.sellWithoutStock,
      "product": _vm.product
    },
    on: {
      "updateQuantity": function (updatedQuantity) {
        return _vm.setQuantity(updatedQuantity);
      }
    }
  }), _vm.shouldShowNoStockError ? _c('p', {
    staticClass: "cart-product__quantity__no-stock"
  }, [_vm._v(" " + _vm._s(_vm.insufficientStockMessage) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "cart-product__price"
  }, [_c('p', [_vm._v(_vm._s(_vm.$currencyFormatter(_vm.product.finalPrice)))]), _vm.product.finalPriceBeforeDiscounts != _vm.product.finalPrice ? _c('p', {
    staticClass: "price-before-discount"
  }, [_vm._v(" " + _vm._s(_vm.$currencyFormatter(_vm.product.finalPriceBeforeDiscounts)) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "cart-product__remove",
    on: {
      "click": function ($event) {
        return _vm.remove();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-trash.svg")
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }