import storeService from '@/services/store'
import store from '@/store'
import { VERSIONS } from '@/utils/constants'

const actions = {
  getDepartments() {
    return new Promise(async (resolve, reject) => {
      try {
        const shouldSearchDepartments = store.getters['shipping/activeCityShipping']

        if (!shouldSearchDepartments) return

        store.set('config/loadingCities', true)

        const applicationVersion = store.get('shop/shop@config.applicationVersion')
        const departments = await storeService.getDepartments({
          application_version: applicationVersion
        })

        store.set('config/departments', departments.data)
        store.set('config/loadingCities', true)

        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  getCities(context, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const shouldSearchCities = store.getters['shipping/activeCityShipping']

        if (!shouldSearchCities) return

        store.set('config/loadingCities', true)

        const applicationVersion = store.get('shop/shop@config.applicationVersion')
        const getCitiesParams = { application_version: applicationVersion }

        const { COLOMBIA, REPUBLICA_DOMINICANA, PANAMA, PERU, ARGENTINA, CHILE } = VERSIONS

        if ([COLOMBIA, PERU].includes(applicationVersion)) {
          getCitiesParams.department_id = payload.departmentId
        }

        if ([REPUBLICA_DOMINICANA, ARGENTINA, PANAMA].includes(applicationVersion)) {
          getCitiesParams.province_id = payload.departmentId
        }

        if ([CHILE].includes(applicationVersion)) {
          getCitiesParams.region_id = payload.departmentId
        }

        const cities = await storeService.getCities(getCitiesParams)

        store.set('config/cities', cities.data)
        store.set('config/loadingCities', true)

        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  getDistricts(context, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const shouldSearchDistricts = store.getters['shipping/activeCityShipping']

        if (!shouldSearchDistricts) return

        store.set('config/loadingCities', true)

        const applicationVersion = store.get('shop/shop@config.applicationVersion')
        const getDistrictsParams = { application_version: applicationVersion }

        const { COSTA_RICA } = VERSIONS

        if ([COSTA_RICA].includes(applicationVersion)) {
          getDistrictsParams.province_id = payload.departmentId
        }

        const cities = await storeService.getDistricts(getDistrictsParams)

        store.set('config/cities', cities.data)
        store.set('config/loadingCities', true)

        resolve()
      } catch (e) {
        reject(e)
      }
    })
  },
  getIdentificationTypes() {
    return new Promise(async (resolve, reject) => {
      try {
        store.set('config/loadingIdentificationTypes', true)

        const applicationVersion = store.get('shop/shop@config.applicationVersion')
        const res = await storeService.getIdentificationTypes({
          application_version: applicationVersion
        })

        store.set('config/identificationTypes', res.data)

        store.set('config/loadingIdentificationTypes', true)

        resolve(res.data)
      } catch (e) {
        reject(e)
      }
    })
  }
}

export default actions
