<template>
  <div v-if="shop" class="level-item level-item-left social-icons">
    <img
      v-if="shop.socialInfo && shop.socialInfo.facebook"
      @click="openSocialNetwork('facebook')"
      src="@/assets/icon-facebook.svg"
      class="pointer"
    />
    <img
      v-if="shop.socialInfo && shop.socialInfo.instagram"
      @click="openSocialNetwork('instagram')"
      src="@/assets/icon-instagram.svg"
      class="pointer"
    />
    <img
      v-if="shop.socialInfo && shop.socialInfo.twitter"
      @click="openSocialNetwork('twitter')"
      src="@/assets/icon-social-x.svg"
      class="pointer"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('shop', ['shop'])
  },
  methods: {
    openSocialNetwork(network) {
      window.open('https://' + network + '.com/' + this.shop.socialInfo[network], '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  margin: 0px 7px;
  width: 40px;
}
</style>
