var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section"
  }, [_c('banner-has-no-iva-days'), _vm.shouldShowBanners ? _c('campaigns-banner') : _vm._e(), _c('featured-products'), _c('app-products')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }