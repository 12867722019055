<template>
  <div class="paypal-payment">
    <Loading class="paypal-payment__loading" v-if="isLoadingPaypal" loading />
    <div v-show="!isLoadingPaypal">
      <div class="paypal-payment__logo">
        <img src="@/assets/logo-paypal.png" alt="Paypal logo" />
      </div>

      <div id="paypal-buttons" />

      <div v-if="isLoadingPaypalCreditCardForm" class="paypal-payment__form-loading">
        <Loading loading />
      </div>
    </div>
  </div>
</template>

<script>
import { loadScript } from '@paypal/paypal-js'
import { mapGetters, mapActions, mapState } from 'vuex'
import store from '@/store'
import { ALLOWED_PAYMENT_METHODS } from '@/utils/constants'
import Loading from '@/components/shared/Loading'

const { PAYPAL } = ALLOWED_PAYMENT_METHODS

export default {
  name: 'PaypalPayment',
  components: {
    Loading
  },
  data() {
    return {
      isLoadingPaypal: true,
      isLoadingPaypalCreditCardForm: false
    }
  },
  computed: {
    ...mapState('shop', ['shop']),
    ...mapGetters('shop', ['conventionalPayments'])
  },
  async created() {
    this.isLoadingPaypal = true

    const paypalData = this.conventionalPayments?.find(method => method.name === PAYPAL)
    const shopConfig = store.getters['shop/shopConfig']
    const currency = shopConfig.currency.code

    if (paypalData) {
      try {
        const paypal = await loadScript({
          'client-id': paypalData.providerConfig.client_id,
          currency
        })

        const createOrder = async () => {
          return await this.createPaypalOrder()
        }

        const onInit = () => {
          this.isLoadingPaypal = false
        }

        const onClick = data => {
          if (data.fundingSource === 'card') {
            this.isLoadingPaypalCreditCardForm = true
          }
        }

        await paypal
          .Buttons({
            createOrder,
            onInit,
            onClick,
            // onApprove: async data => {
            //   console.log(data)
            // try {
            //   const response = await fetch('/your-server-endpoint/capture-paypal-order', {
            //     method: 'POST',
            //     headers: {
            //       'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //       orderId: data.orderID,
            //     }),
            //   });

            //   const captureDetails = await response.json();

            //   // Handle successful payment
            //   console.log('Payment completed successfully:', captureDetails);
            // } catch (error) {
            //   console.error('Failed to capture PayPal order', error);
            // }
            // },
            onError: () => {
              // Handle errors
              throw new Error('Payment error')
            }
          })
          .render('#paypal-buttons')
      } catch {
        throw new Error('Failed to load the PayPal JS SDK script')
      }
    }
  },
  methods: {
    ...mapActions('order', ['newOrder']),
    async createPaypalOrder() {
      try {
        const order = await this.newOrder()

        const paypalOrderId = order.payments[0].paymentInfo.order_id

        if (order.id) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'eventApp',
            eventAppCategory: 'Shop',
            eventAppAction: 'New Order',
            eventAppLabel: `${this.shop.id}`
          })
        }

        this.isLoadingPaypalCreditCardForm = false

        return paypalOrderId
      } catch {
        throw new Error('Failed to create PayPal order')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.paypal-payment {
  max-width: 420px;
  margin: 0 auto;
  padding-top: 75px;
  min-height: 30vh;

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  #paypal-buttons {
    position: relative;
    z-index: 1;
  }

  &__loading {
    margin-top: 5vh;
  }

  &__logo {
    margin: 0 auto 40px;
    width: 80px;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  &__form-loading {
    height: 30vh;
    display: grid;
  }
}
</style>
