var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "applied-coupon"
  }, [_c('span', {
    staticClass: "applied-coupon__promo-code"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/icon-coupon.svg'),
      "alt": "Icono de descuento"
    }
  }), _vm._v(" " + _vm._s(_vm.appliedCoupon.code) + " ")]), _c('span', {
    staticClass: "applied-coupon__discount"
  }, [_vm._v("- " + _vm._s(_vm.appliedCoupon.discountPercentage) + "%")]), _vm.shouldShowRemoveCouponButton ? _c('div', {
    staticClass: "applied-coupon__close",
    on: {
      "click": function ($event) {
        return _vm.$emit('remove-coupon');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/icon-close.svg'),
      "alt": "Icono de cerrar"
    }
  })]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }