<template>
  <form @submit.prevent="submitInput" class="coupon-input">
    <label for="couponInput">
      <img :src="require('@/assets/icon-coupon.svg')" alt="Icono de descuento" /> CUPÓN
    </label>
    <div
      class="coupon-input__field"
      :class="{ 'coupon-input__field--error': couponError && !removeError }"
    >
      <div class="coupon-input__field__input-container">
        <input
          v-model.trim="promoCode"
          id="couponInput"
          type="text"
          placeholder="Escribe el codigo de tu cupón"
        />
        <span v-if="couponError && !removeError" class="coupon-input__field__error-hint">
          Código no válido
        </span>
      </div>
      <button
        type="submit"
        class="button"
        :class="{ 'is-loading': loading }"
        :disabled="couponError && !removeError"
      >
        Aplicar
      </button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'CouponSection',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    couponError: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      promoCode: '',
      removeError: false
    }
  },
  watch: {
    promoCode() {
      this.removeError = true
    }
  },
  methods: {
    submitInput() {
      this.removeError = false
      if (!this.promoCode) return

      this.$emit('input-submit', this.promoCode)
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-input {
  padding: 0 25px;

  label {
    display: flex;
    align-items: center;
    color: #4a90e2;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 7px;

    img {
      margin-top: -2px;
      margin-right: 7px;
    }
  }

  &__field {
    display: flex;

    &__input-container {
      flex: 1;
      display: flex;
      position: relative;
    }

    input {
      width: 100%;
      padding: 9px 20px 9px 13px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      margin-right: 4px;

      &::placeholder {
        color: #c2c9d6;
      }
    }

    button {
      padding: 8px;
      background: #4a90e2;
      border-radius: 5px;
      border: none;
      color: #fff;
      width: 70px;
      cursor: pointer;
    }

    button.is-loading {
      pointer-events: none;
    }

    button:disabled {
      cursor: auto;
    }

    &__error-hint {
      color: #be123c;
      font-size: 11px;
      position: absolute;
      bottom: -16px;
    }
  }

  &__field--error {
    input {
      border: 1px solid #e11d48;
    }
  }
}
</style>
