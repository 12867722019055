var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "quantity-input"
  }, [_c('div', {
    staticClass: "quantity-input__control",
    on: {
      "click": _vm.decrement
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-minus.svg")
    }
  })]), _c('div', {}, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.localQuantity,
      expression: "localQuantity"
    }],
    staticClass: "quantity-input__field",
    staticStyle: {
      "-moz-appearance": "textfield"
    },
    attrs: {
      "type": "number",
      "disabled": _vm.shouldDisableInput
    },
    domProps: {
      "value": _vm.localQuantity
    },
    on: {
      "keydown": _vm.removeDecimals,
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.localQuantity = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "quantity-input__control",
    class: {
      'disable-increment': _vm.shouldDisableIncrement
    },
    on: {
      "click": _vm.increment
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-plus.svg")
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }