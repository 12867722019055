<template>
  <div class="no-stock-banner">
    <div class="no-stock-banner__icon">
      <img src="@/assets/icon-warning-triangle.svg" />
    </div>
    <div>
      <h3 class="no-stock-banner__heading">
        Producto no disponible o agotado
      </h3>
      <p class="no-stock-banner__description">
        Uno o varios de los productos añadidos al carrito no están disponibles en este momento, por
        este motivo no es posible finalizar la compra.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoStockBanner'
}
</script>

<style lang="scss" scoped>
.no-stock-banner {
  background-color: #fef3c7;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 25px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    padding: 10px;
    background-color: #fdeeae;
    border-radius: 100%;
    margin-right: 16px;

    @media (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }

  &__heading {
    color: #0f172a;
    font-weight: 600;
    font-size: 20px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__description {
    color: #334155;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
}
</style>
