var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.lastOrder ? _c('section', [_c('div', {
    staticClass: "notification-order level"
  }, [_c('div', {
    staticClass: "level-item status"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("@/assets/icon-truck.svg")
    }
  }), _c('div', {
    staticClass: "comlumns title-status"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('h2', [_vm._v(" TU PEDIDO HA SIDO CREADO ")]), _vm.lastOrder.paymentMethod.type == 'manual' ? _c('p', [_vm._v("Sigue las instrucciones a continuación "), _c('span', {
    staticClass: "desktop"
  }, [_vm._v("y coordina con el vendedor")])]) : _vm._e()])])]), _c('div', {
    staticClass: "level-item number-order"
  }, [_c('h2', [_vm._v("Nº PEDIDO")]), _c('span', [_vm._v(_vm._s(_vm.lastOrder.number))])])]), !_vm.lastOrder.paymentMethod.provider ? _c('div', {
    staticClass: "container-custom"
  }, [_c('div', {
    staticClass: "title-container"
  }, [_c('img', {
    staticClass: "logo-payment",
    attrs: {
      "src": _vm.lastOrder.paymentMethod.logoFiles ? _vm.lastOrder.paymentMethod.logoFiles.url : require('@/assets/icon-payment-generic.svg')
    }
  }), _c('h3', [_vm._v(_vm._s(_vm.lastOrder.paymentMethod.name))])]), _c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('div', {
    staticClass: "pre"
  }, [_vm._v(_vm._s(_vm.lastOrder.paymentMethod.paymentInstructions))])]), _vm.lastOrder.paymentMethod.instructionsFiles ? _c('div', {
    staticClass: "column"
  }, [_c('div', {
    staticClass: "container-qr"
  }, [_c('img', {
    attrs: {
      "src": _vm.lastOrder.paymentMethod.instructionsFiles.url
    }
  })])]) : _vm._e()])]) : _vm._e(), _vm.lastOrder.paymentMethod.provider === 'WHATSAPP' ? _c('div', {
    staticClass: "container-whatsapp"
  }, [_vm._m(0), _vm._m(1)]) : _vm._e(), _vm.lastOrder.paymentMethod.provider == 'NEQUI' ? _c('div', {
    staticClass: "container-nequi"
  }, [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column"
  }, [_c('img', {
    staticClass: "image-payment",
    attrs: {
      "src": require("@/assets/logo-full-nequi.png")
    }
  }), _c('p', [_vm._v("Escanea este código QR con tu app Nequi y una vez realizado el pago haz clic en el botón Verificar pago de Nequi.")]), _c('p', [_vm._v("Si tienes consultas o inquietudes sobre tu compra puedes comunicarte al teléfono de contacto indicado en la parte inferior.")])]), _c('div', {
    staticClass: "column"
  }, [_c('div', {
    staticClass: "container-qr"
  }, [_c('vue-qr', {
    attrs: {
      "text": _vm.lastOrder.payments[0].paymentInfo.qr,
      "size": 220,
      "margin": 0
    }
  })], 1)])])]) : _vm._e(), ['PAYU', 'MERCADO_PAGO', 'GREENPAY', 'VISANET'].includes(_vm.lastOrder.paymentMethod.provider) ? _c('div', {
    staticClass: "container-automatic-pay",
    class: _vm.lastOrder.paymentMethod.provider.toLowerCase()
  }, [_c('img', {
    staticClass: "image-payment",
    attrs: {
      "src": require(("@/assets/logo-full-" + (_vm.lastOrder.paymentMethod.provider.toLowerCase()) + ".png"))
    }
  }), !_vm.openedExternalPayment ? _c('p', [_vm._v(_vm._s(_vm.redirectText))]) : _vm._e(), _c('app-loading', {
    attrs: {
      "loading": !_vm.openedExternalPayment
    }
  }), ['MERCADO_PAGO', 'GREENPAY'].includes(_vm.lastOrder.paymentMethod.provider) && _vm.openedExternalPayment ? _c('p', {
    staticClass: "open-link-pay"
  }, [_vm._v(" Si no fuiste redirigido "), _c('a', {
    attrs: {
      "href": this.lastOrder.payments[0].paymentInfo.url,
      "target": "_blank"
    }
  }, [_vm._v("haz clic aquí")]), _vm._v(" para continuar con el pago ")]) : _vm._e(), ['MERCADO_PAGO', 'GREENPAY'].includes(_vm.lastOrder.paymentMethod.provider) && _vm.openedExternalPayment ? _c('p', [_vm._v(" Luego de realizar el pago, espera un momento y validaremos el estado ")]) : _vm._e()], 1) : _vm._e()]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-pay"
  }, [_c('h2', [_vm._v("INSTRUCCIONES DE PAGO")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-body"
  }, [_c('div', {
    staticClass: "body-pay whatsapp"
  }, [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column is-6"
  }, [_c('img', {
    staticClass: "logo-payment",
    attrs: {
      "src": require("@/assets/logo-whatsapp.png")
    }
  }), _c('p', [_vm._v("¡Muchas gracias por tu pedido! para coordinar el pago haz clic en el botón, "), _c('strong', [_vm._v("Enviar pedido vía WhatsApp")])]), _c('p', [_vm._v("Si tienes consultas o inquietudes sobre tu compra puedes comunicarte al teléfono de contacto indicado en la parte inferior.")])]), _c('img', {
    staticClass: "img-phone",
    attrs: {
      "src": require("@/assets/phone-whatsapp.png")
    }
  })])])]);
}]

export { render, staticRenderFns }