import { make } from 'vuex-pathify'
import actions from './actions'

const inistialState = () => ({
  categories: null,
  loadingCategories: true,
  categorieSelected: null
})

const state = inistialState()

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
