<template>
  <section class="regret-section">
    <div class="regret-section-heading">
      <h5>¿Cambiaste de opinión con tu compra?</h5>
      <!-- <span>
        <img src="@/assets/icon-info.svg" alt="Icono de ayuda" />
      </span> -->
    </div>

    <p>
      Si te arrepentiste de tu compra, podés solicitar el reembolso de tu dinero en un plazo de
      hasta 10 días desde la fecha en que realizaste la compra.
    </p>

    <button @click="$modal.show('regret-form-modal')">Botón de arrepentimiento</button>

    <RegretFormModal />
    <RegretSuccessModal />
  </section>
</template>

<script>
import RegretFormModal from './RegretFormModal.vue'
import RegretSuccessModal from './RegretSuccessModal.vue'

export default {
  name: 'FooterRegretSection',
  components: {
    RegretFormModal,
    RegretSuccessModal
  },
  mounted() {
    // this.$modal.show('regret-success-modal')
    // this.$modal.show('regret-form-modal')
  }
}
</script>

<style lang="scss" scoped>
.regret-section {
  max-width: 437px;
  padding: 16px;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  align-items: baseline;

  @media (min-width: 768px) {
    margin-left: 10px;
  }

  .regret-section-heading {
    display: flex;
    align-items: center;

    h5 {
      color: #0f172a;
      font-size: 14px;
      font-weight: 600;
      margin-right: 10px;
      margin-bottom: 8px;
    }

    span {
      cursor: pointer;
    }
  }

  p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    color: #334155;
  }

  button {
    color: #0f172a;
    font-weight: 500;
    padding: 6px 16px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid #cbd5e1;
    background: transparent;
    line-height: 12px;
    margin-top: auto;
    cursor: pointer;

    &:hover {
      background: rgba(226, 232, 240, 0.45);
    }
  }
}
</style>
