var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section"
  }, [_vm.showNoStockWarning ? _c('NoStockBanner') : _vm._e(), _c('banner-has-no-iva-days'), _vm.loadingCheckout ? _c('app-loading', {
    attrs: {
      "loading": true
    }
  }) : _c('div', {
    staticClass: "columns container-layout"
  }, [_c('div', {
    staticClass: "column is-two-thirds column-main"
  }, [_c('div', {
    staticClass: "level header-cart"
  }, [_c('div', {
    staticClass: "level-item",
    class: _vm.step === 'CURRENT_PRODUCTS' ? 'step-active' : 'step-inactive'
  }, [_c('h2', {
    staticClass: "subtitle-cart"
  }, [_c('span', {
    staticClass: "text-long"
  }, [_vm._v("PEDIDO ACTUAL")]), _c('span', {
    staticClass: "text-short mobile"
  }, [_vm._v("1")]), _c('span', {
    staticClass: "icon"
  }, [_vm.step === 'CURRENT_PRODUCTS' ? _c('img', {
    attrs: {
      "src": require("@/assets/icon-arrow-right-black.svg")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icon-arrow-right-gray.svg")
    }
  })])])]), _c('div', {
    staticClass: "level-item",
    class: _vm.step === 'DATA_CONTACT' ? 'step-active' : 'step-inactive'
  }, [_c('h2', {
    staticClass: "subtitle-cart"
  }, [_c('span', {
    staticClass: "text-long"
  }, [_vm._v("DATOS DE CONTACTO")]), _c('span', {
    staticClass: "text-short mobile"
  }, [_vm._v("2")]), _c('span', {
    staticClass: "icon"
  }, [_vm.step === 'DATA_CONTACT' ? _c('img', {
    attrs: {
      "src": require("@/assets/icon-arrow-right-black.svg")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icon-arrow-right-gray.svg")
    }
  })])])]), _c('div', {
    staticClass: "level-item",
    class: _vm.step === 'PAYMENT_METHOD' ? 'step-active' : 'step-inactive'
  }, [_c('h2', {
    staticClass: "subtitle-cart"
  }, [_c('span', {
    staticClass: "text-long"
  }, [_vm._v("MÉTODO DE PAGO")]), _c('span', {
    staticClass: "text-short mobile"
  }, [_vm._v("3")]), _c('span', {
    staticClass: "icon"
  }, [_vm.step === 'PAYMENT_METHOD' ? _c('img', {
    attrs: {
      "src": require("@/assets/icon-arrow-right-black.svg")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/icon-arrow-right-gray.svg")
    }
  })])])]), _c('div', {
    staticClass: "level-item",
    class: _vm.step === 'CONFIRM_SUMMARY' || _vm.step === 'CONFIRM_SUCCESS' || _vm.step === 'CHECKOUT_PAYMENT' ? 'step-active' : 'step-inactive'
  }, [_c('h2', {
    staticClass: "subtitle-cart"
  }, [_c('span', {
    staticClass: "text-long"
  }, [_vm._v("CONFIRMACIÓN")]), _c('span', {
    staticClass: "text-short mobile"
  }, [_vm._v("4")])])])]), _c('div', {
    staticClass: "main-cart"
  }, [_vm.step === 'CURRENT_PRODUCTS' ? _c('app-products-in-cart') : _vm._e(), _c('app-order-shipping', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.step === 'DATA_CONTACT',
      expression: "step === 'DATA_CONTACT'"
    }]
  }), _vm.step === 'PAYMENT_METHOD' ? _c('app-order-payment') : _vm._e(), _vm.step === 'CONFIRM_SUMMARY' ? _c('app-summary-confirm') : _vm._e(), _vm.step === 'PAYPAL_PAYMENT' && _vm.conventionalPayments.length ? _c('PaypalPayment') : _vm._e(), _vm.step === 'CONFIRM_SUCCESS' ? _c('app-order-success') : _vm._e(), _vm.step === 'CHECKOUT_PAYMENT' ? _c('app-checkout-payment') : _vm._e()], 1), _vm.step != 'CURRENT_PRODUCTS' && _vm.step != 'CONFIRM_SUCCESS' && _vm.step != 'CHECKOUT_PAYMENT' ? _c('div', {
    staticClass: "back-container"
  }, [_c('div', {
    staticClass: "pointer",
    on: {
      "click": _vm.lastStep
    }
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-arrow-left.svg")
    }
  }), _vm._v(" Volver ")])])]) : _vm._e(), _vm.shop && _vm.shop.contactInfo.phone && (_vm.step == 'CONFIRM_SUCCESS' || _vm.step == 'CHECKOUT_PAYMENT') ? _c('div', {
    staticClass: "contact-container"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v("Si tienes consultas puedes comunicarte al teléfono")]), _c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-phone.svg")
    }
  })]), _c('div', {
    staticClass: "number"
  }, [_vm._v(" " + _vm._s(_vm.shop.contactInfo.phone) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "column column-invoice"
  }, [_c('div', {
    staticClass: "level header-cart",
    on: {
      "click": function ($event) {
        _vm.showDetail = !_vm.showDetail;
      }
    }
  }, [_c('div', {
    staticClass: "level-item"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-shopping-car.svg")
    }
  })]), _c('h2', {
    staticClass: "subtitle-invoice"
  }, [_vm._v("RESUMEN DEL PEDIDO")])]), _c('div', {
    staticClass: "level-right mobile"
  }, [_c('span', {
    staticClass: "button-show-detail"
  }, [_vm._v(_vm._s(_vm.showDetail ? 'ocultar' : 'ver'))])])]), _c('app-invoice', {
    attrs: {
      "step": _vm.step,
      "loading-next": _vm.loadingNext,
      "showDetail": _vm.showDetail
    },
    on: {
      "nextStep": _vm.nextStep,
      "go-to-start": _vm.goToStart,
      "changeShowDetail": _vm.changeShowDetail
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }