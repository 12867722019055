<template>
  <section class="campaigns">
    <carousel
      :per-page="1"
      :mouse-drag="true"
      autoplay
      :autoplay-timeout="3000"
      loop
      pagination-color="#E2E8F0"
      pagination-active-color="#0F172A"
      :pagination-size="24"
      :pagination-padding="8"
    >
      <slide v-for="campaign in activeCampaigns" :key="campaign.id">
        <div>
          <a :href="campaign.url" target="_blank">
            <img :src="campaign.imageUrlDesktop" :alt="campaign.name" />
          </a>
        </div>
      </slide>
    </carousel>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CampaignsBanner',
  computed: {
    ...mapState('campaigns', ['activeCampaigns'])
  }
}
</script>

<style lang="scss">
.campaigns {
  .VueCarousel-pagination {
    .VueCarousel-dot-container {
      margin-top: -9px !important;
    }

    .VueCarousel-dot {
      height: 2px !important;
      border-radius: initial;
    }
  }
}
</style>

<style lang="scss" scoped>
.campaigns {
  margin-bottom: 60px;
  object-fit: cover;

  img {
    width: 100%;
    height: 380px;
    object-fit: cover;

    @media (min-width: 991px) and (max-width: 1200px) {
      height: 300px;
    }

    @media (max-width: 990px) {
      height: 350px;
    }

    @media (max-width: 600px) {
      object-fit: cover;
      height: auto;
    }
  }
}
</style>
