var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: _vm.colorsVars,
    attrs: {
      "id": "app"
    }
  }, [_vm.loading ? _c('app-loading', {
    style: {
      marginTop: '200px'
    },
    attrs: {
      "loading": true,
      "size": _vm.isMobile ? 'medium' : 'large'
    }
  }) : _vm.shop && _vm.shop.status == 'inactive' && _vm.mode !== 'preview' ? _c('page-shop-not-available') : _c('div', {
    staticClass: "app-main"
  }, [_vm.$router.currentRoute.name != 'terms' && _vm.$router.currentRoute.name != 'shop-not-available' ? _c('app-header', {
    staticClass: "desktop"
  }) : _vm._e(), _vm.$router.currentRoute.name != 'shop-not-available' ? _c('app-header-mobile', {
    staticClass: "mobile"
  }) : _vm._e(), _vm.showMenuMobile ? _c('div', {
    staticClass: "background-menu mobile",
    on: {
      "click": function ($event) {
        return _vm.closeMenu();
      }
    }
  }) : _vm._e(), _c('transition', {
    attrs: {
      "name": "menu-fade"
    }
  }, [!_vm.isMobile && _vm.$router.currentRoute.name != 'terms' && _vm.$router.currentRoute.name != 'shop-not-available' || _vm.isMobile && _vm.showMenuMobile ? _c('div', {
    staticClass: "column-aside"
  }, [_c('aside', {
    staticClass: "aside-categories"
  }, [_c('app-categories')], 1)]) : _vm._e()]), _c('div', {
    staticClass: "column-main"
  }, [_vm.shop && (_vm.shop.status == 'active' || _vm.mode === 'preview') ? _c('main', {
    staticClass: "main"
  }, [_c('router-view')], 1) : _vm._e(), _vm.shop && _vm.shop.status == 'inactive' && _vm.mode !== 'preview' ? _c('div', {
    staticClass: "shop-inactive"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-settings.svg")
    }
  }), _c('h2', [_vm._v(_vm._s(_vm.shop.name) + " no está disponible.")])]) : _vm._e(), _vm.$router.currentRoute.name != 'shop-not-available' ? _c('app-footer', {
    staticClass: "app-footer"
  }) : _vm._e()], 1)], 1), _vm.infoModalIsOpen ? _c('app-info-modal', {
    attrs: {
      "title": _vm.infoModalData.title,
      "description": _vm.infoModalData.description
    },
    on: {
      "toggle-modal": _vm.closeInfoModal
    }
  }) : _vm._e(), _c('app-product-added-success'), _c('app-add-product-whith-variants'), _c('app-notification')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }