import apiAlegraStoresService from './api-alegra-stores'

const service = {}

service.getShippingMethods = (idStore, { city }) => {
  return apiAlegraStoresService.get(`/stores/${idStore}/shipping-methods`, { params: { status: 'active', city } })
    .then(res => res.data)
    .catch(e => e)
}

export default service
