var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('div', [_c('div', {
    staticClass: "loader-wrapper is-active",
    class: _vm.size
  }, [_c('div', {
    staticClass: "loader is-warning is-loading"
  })]), _vm._t("default")], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }