import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import App from './App.vue'

import Vuelidate from 'vuelidate'
import Big from 'big.js'
import VModal from 'vue-js-modal'

import store from '@/store'

import { currencyFormatter, formatCurrencyForCalculations } from './utils/currency'

import currencyFilter from '@/filters/currency'
import routes from './routes'
import * as Sentry from '@sentry/vue'

import VueCarousel from 'vue-carousel'
import InlineSvg from 'vue-inline-svg'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.prototype.$currencyFormatter = currencyFormatter
Vue.prototype.$formatCurrencyForCalculations = formatCurrencyForCalculations
Vue.prototype.$Big = Big

Vue.use(VueMeta)
Vue.use(VueRouter)
Vue.use(currencyFilter)
Vue.use(Vuelidate)
Vue.use(VueCarousel)
Vue.use(VModal)
Vue.component('InlineSvg', InlineSvg)

if (process.env.VUE_APP_ENVIROMENT !== 'local') {
  Sentry.init({
    Vue: Vue,
    environment: process.env.VUE_APP_ENVIROMENT,
    dsn: 'https://77889992958948dda74f9b9f613f4177@o509533.ingest.sentry.io/5604179',
    debug: !(process.env.VUE_APP_ENVIROMENT === 'production'),
    release: process.env.VUE_APP_COMMIT_HASH ? String(process.env.VUE_APP_COMMIT_HASH) : '0'
  })
}

const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  el: '#app',
  beforeCreate() {
    this.$store.dispatch('cart/initialise')
  },
  render: h => h(App),
  router,
  store
})
