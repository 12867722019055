import apiAlegraStoresService from './api-alegra-stores'

const orderService = {}

orderService.newOrder = (idStore, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await apiAlegraStoresService.post(`/stores/${idStore}/orders`, params)
      resolve(data)
    } catch (e) {
      reject(e)
    }
  })
}

orderService.getOrder = (idStore, idOrder, extraAttrs) => {
  return apiAlegraStoresService
    .get(`/stores/${idStore}/orders/${idOrder}?extraAttrs=${extraAttrs}`)
    .then(res => res.data)
    .catch(e => e)
}

orderService.getPayment = (idStore, idPayment) => {
  return apiAlegraStoresService
    .get(`/stores/${idStore}/payments/${idPayment}/verify`)
    .then(res => res.data)
    .catch(e => e)
}

orderService.regretOrder = (idStore, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await apiAlegraStoresService.patch(
        `/stores/${idStore}/orders/regret-order`,
        params
      )
      resolve(data)
    } catch (e) {
      reject(e)
    }
  })
}

export default orderService
