var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "regret-success-modal",
    attrs: {
      "name": "regret-success-modal",
      "adaptive": "",
      "scrollable": "",
      "focusTrap": "",
      "max-width": 320,
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "regret-success-container"
  }, [_c('span', {
    staticClass: "success-icon"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "54",
      "height": "53",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill": "#30ABA9",
      "d": "M34.796 23.255a1.656 1.656 0 0 0-2.342-2.343l-7.662 7.662-3.246-3.245a1.656 1.656 0 1 0-2.342 2.342l4.416 4.417a1.656 1.656 0 0 0 2.343 0l8.833-8.834Z"
    }
  }), _c('path', {
    attrs: {
      "fill": "#30ABA9",
      "fill-rule": "evenodd",
      "d": "M27 4.969c-11.891 0-21.531 9.64-21.531 21.531 0 11.891 9.64 21.531 21.531 21.531 11.891 0 21.531-9.64 21.531-21.531 0-11.891-9.64-21.531-21.531-21.531ZM8.781 26.5C8.781 16.438 16.938 8.281 27 8.281c10.062 0 18.219 8.157 18.219 18.219 0 10.062-8.157 18.219-18.219 18.219-10.062 0-18.219-8.157-18.219-18.219Z",
      "clip-rule": "evenodd"
    }
  })])]), _c('p', [_vm._v("Tu solicitud de arrepentimiento se envió exitosamente.")]), _vm.idCancelRequest ? [_c('p', [_c('strong', [_vm._v("Número de reclamo")])]), _c('h5', [_vm._v(_vm._s(_vm.idCancelRequest))])] : _vm._e(), _c('p', [_vm._v("Te enviamos un correo con toda la información.")]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('regret-success-modal');
      }
    }
  }, [_vm._v("Cerrar")])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }