<template>
  <div v-if="hasNoIvaDay" class="hasNoIvaDays">
    <div class="columns is-mobile">
      <div class="column has-text-centered">
        <span class="icon">
          <img src="@/assets/icon-discount.svg" />
        </span>
      </div>
      <div class="column is-11">
        <h4>¡DÍA SIN IVA! <span>Termina hoy a las 11:59 pm</span></h4>
        <p>
          Para realizar tus compras sin IVA, recuerda seguir las reglamentaciones según la
          <span>DIAN</span>. En caso de que no se cumplan estas condiciones tu compra puede incurrir
          en el cobro del <span>IVA</span>.
        </p>
      </div>
    </div>
    <a
      target="_blank"
      href="https://ayuda.alegra.com/es/prep%C3%A1rate-y-factura-durante-los-d%C3%ADas-sin-iva-en-colombia"
      class="helpNoIvaDays"
    >
      Ver condiciones del día sin IVA
    </a>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'

export default {
  name: 'BannerHasNoIvaDays',
  computed: {
    hasNoIvaDay: get('shop@shop.config.hasNoIvaDay')
  }
}
</script>

<style lang="scss" scoped>
.hasNoIvaDays {
  position: relative;
  background: #ffffff;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 30px 30px 30px;
  margin-bottom: 20px;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #00b19d;
    margin-bottom: 15px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #47536b;
    max-width: 600px;
    span {
      color: #00b19d;
    }
  }
}
.helpNoIvaDays {
  position: absolute;
  right: 16px;
  bottom: 13px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 146.19%;
  text-decoration-line: underline;
  color: #00b19d;
}
@media (max-width: 768px) {
  .hasNoIvaDays {
    padding: 10px 10px 30px 10px;
    h4 {
      font-size: 14px;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
      }
    }
    p {
      font-size: 12px;
      width: 90%;
    }
  }
}
</style>
