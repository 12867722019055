var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasNoIvaDay ? _c('div', {
    staticClass: "hasNoIvaDays"
  }, [_vm._m(0), _c('a', {
    staticClass: "helpNoIvaDays",
    attrs: {
      "target": "_blank",
      "href": "https://ayuda.alegra.com/es/prep%C3%A1rate-y-factura-durante-los-d%C3%ADas-sin-iva-en-colombia"
    }
  }, [_vm._v(" Ver condiciones del día sin IVA ")])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "columns is-mobile"
  }, [_c('div', {
    staticClass: "column has-text-centered"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-discount.svg")
    }
  })])]), _c('div', {
    staticClass: "column is-11"
  }, [_c('h4', [_vm._v("¡DÍA SIN IVA! "), _c('span', [_vm._v("Termina hoy a las 11:59 pm")])]), _c('p', [_vm._v(" Para realizar tus compras sin IVA, recuerda seguir las reglamentaciones según la "), _c('span', [_vm._v("DIAN")]), _vm._v(". En caso de que no se cumplan estas condiciones tu compra puede incurrir en el cobro del "), _c('span', [_vm._v("IVA")]), _vm._v(". ")])])]);
}]

export { render, staticRenderFns }