<template>
  <div class="quantity-input">
    <div @click="decrement" class="quantity-input__control">
      <img src="@/assets/icon-minus.svg" />
    </div>
    <div class="">
      <input
        v-model="localQuantity"
        class="quantity-input__field"
        @keydown="removeDecimals"
        type="number"
        :disabled="shouldDisableInput"
        style="-moz-appearance: textfield"
      />
    </div>
    <div
      @click="increment"
      class="quantity-input__control"
      :class="{ 'disable-increment': shouldDisableIncrement }"
    >
      <img src="@/assets/icon-plus.svg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    quantity: {
      type: Number,
      required: true,
      default: 1
    },
    product: {
      type: Object,
      required: true
    },
    maxQuantity: {
      type: Number || null,
      default: null
    },
    sellWithoutStock: {
      type: Boolean || null,
      default: null
    }
  },
  data() {
    return {
      localQuantity: this.quantity
    }
  },
  watch: {
    quantity() {
      this.$forceUpdate()
    },
    maxQuantity(newVal) {
      if (newVal) {
        this.localQuantity = newVal < 0 ? 0 : this.quantity
      }
    },
    localQuantity(newVal) {
      const MAX_QUANTITY_ALLOWED = 1000000
      const newQuantityValue = parseInt(newVal)

      if (newQuantityValue <= 0 || isNaN(newQuantityValue)) this.localQuantity = 1

      if (newQuantityValue > MAX_QUANTITY_ALLOWED) this.localQuantity = MAX_QUANTITY_ALLOWED

      if (this.maxQuantity && this.localQuantity > this.maxQuantity) {
        this.localQuantity = this.maxQuantity < 0 ? 0 : this.maxQuantity
      }

      this.$emit('updateQuantity', parseInt(newVal))
    }
  },
  computed: {
    shouldDisableIncrement() {
      if (this.sellWithoutStock) return false
      return this.quantity >= this.maxQuantity
    },
    shouldDisableInput() {
      if (!this.sellWithoutStock && this.maxQuantity === 0) return true

      return false
    }
  },
  created() {
    this.localQuantity = this.quantity
  },
  methods: {
    increment() {
      if (this.shouldDisableIncrement) return
      if (this.product.hasNoIvaDays && this.quantity >= 3) return

      this.localQuantity = this.quantity + 1
      this.$emit('updateQuantity', this.quantity + 1)
    },
    decrement() {
      if (this.quantity === 1) return

      this.localQuantity = this.quantity - 1
      this.$emit('updateQuantity', this.quantity - 1)
    },
    removeDecimals(event) {
      const intRx = /\d/

      if (
        event.key.length > 1 ||
        (event.key === '-' && !event.currentTarget.value.length) ||
        intRx.test(event.key)
      ) {
        return
      }
      event.preventDefault()
    }
  }
}
</script>

<style lang="scss" scoped>
.disable-increment {
  pointer-events: none;
  opacity: 0.3;
}

.quantity-input {
  z-index: 2;
  display: flex;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
  padding: 7px;

  &__control {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 35px;
      height: 40px;
      transform: translateX(-35%);
    }
  }

  &__field {
    width: 60px;
    padding: 3px 5px;
    border: none;
    background: transparent;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #334155;
    outline: none;
  }
}

// Ocultar controles para input numerico
/* Chrome, Safari, Edge, Opera */
.quantity-input__field::-webkit-outer-spin-button,
.quantity-input__field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
