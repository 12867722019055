<template>
  <div class="content" :style="{backgroundImage: `url(${require(`@/assets/background-products.jpg`)})`}">
    <div class="container-main">
      <div class="brand-tienda link" @click="goLanding()">
        <img src="@/assets/icon-shop-brand.svg">
        ALEGRA TIENDA
      </div>
      <h1>La tienda <span v-if="shop && shop.name">{{ shop.name }}</span> se encuentra inactiva en este momento </h1>
      <p>{{ contactText }}</p>
      <div class="container-register">
        ¿Quieres tener tu propia tienda en línea?
        <a href="https://www.alegra.com/tienda">Regístrate ahora</a>
      </div>
    </div>
    <div class="container-footer">
      <div class="columns">
        <div class="column desktop">
          <a
            href="https://www.alegra.com/tienda"
            target="_blank"
            class="option-footer">Alegra Tienda</a>
          <a
            href="https://ayuda.alegra.com/es/configura-tu-tienda-en-l%C3%ADnea-y-comienza-a-vender-con-alegra-tienda"
            target="_blank"
            class="option-footer">Ayuda</a>
          <a
            href="https://www.alegra.com/tienda/#empresas"
            target="_blank"
            class="option-footer">Tiendas</a>
        </div>
        <div class="column your-shop-footer">
          <a href="https://tienda.alegra.com/home">¿Es tu tienda? <span>Actívate ahora</span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('shop', ['shop']),
    contactText: function() {
      let text = ''
      if (this.shop && this.shop.contactInfo) {
        const contactInfo = this.shop.contactInfo
        if (contactInfo.phone && contactInfo.email) {
          text = `Te recomendamos ponerte en contacto al número ${contactInfo.phone} o al correo ${contactInfo.email} para recibir más información.`
        } else if (contactInfo.phone) {
          text = `Te recomendamos ponerte en contacto al número ${contactInfo.phone} para recibir más información.`
        } else if (contactInfo.email) {
          text = `Te recomendamos ponerte en contacto al correo ${contactInfo.email} para recibir más información.`
        }
      }
      return text
    }
  },
  methods: {
    goLanding() {
      window.open('https://www.alegra.com/tienda', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}
.container-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  text-align: center;
  height: 95%;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(55, 55, 55, 0.48) 100%);
  padding-top: 40px;
  .brand-tienda {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 159.86px;
    height: 39.78px;
    background: #FFFFFF;
    border-radius: 68px;
    margin-bottom: 180px;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 115%;
    color: #707070;
    img {
      margin-right: 10px;
    }
  }
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 100%;
    color: #FFFFFF;
    width: 420px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 132%;
    color: #EAEAEA;
    margin-bottom: 40px !important;
    width: 400px;
  }
  .container-register {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 132%;
    color: #EAEAEA;
    a {
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}
.container-footer {
  position: absolute;
  bottom: 0;
  height: 5%;
  width: 100%;
  background: #fff;
  padding: 0 40px;
  .columns {
    margin: 0;
    padding: 0;
    height: 100%;
    .column {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0;
      height: 100%;
    }
    .column.your-shop-footer {
      justify-content: flex-end;
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 141%;
        color: #707070;
      }
      span {
        text-decoration: underline;
      }
    }
  }
  .option-footer {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 141%;
    color: #474747;
    margin-right: 40px;
  }
}

@media (max-width: 768px) {
  .content {
    background-size: 150%;
  }
  .container-main {
    .brand-tienda {
      margin-bottom: 120px;
    }
    h1 {
      font-size: 24px;
      width: 90%;
    }
    p {
      font-size: 15px;
      margin-bottom: 60px !important;
      width: 80%;
    }
    .container-register {
      font-size: 13px;
    }
  }
  .container-footer {
    .columns {
      margin: 0;
      padding: 0;
      height: 100%;
      .column {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: 100%;
      }
    }
    .your-shop-footer {
      justify-content: center !important;
    }
  }
}
</style>
