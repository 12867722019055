var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "regret-section"
  }, [_vm._m(0), _c('p', [_vm._v(" Si te arrepentiste de tu compra, podés solicitar el reembolso de tu dinero en un plazo de hasta 10 días desde la fecha en que realizaste la compra. ")]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show('regret-form-modal');
      }
    }
  }, [_vm._v("Botón de arrepentimiento")]), _c('RegretFormModal'), _c('RegretSuccessModal')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "regret-section-heading"
  }, [_c('h5', [_vm._v("¿Cambiaste de opinión con tu compra?")])]);
}]

export { render, staticRenderFns }