<template>
  <div>
    <app-loading v-if="loadingProducts" :loading="loadingProducts" />
    <template v-else>
      <div v-if="shouldShowSearchResultAmount" class="results-search">
        Mostrando {{ metadata.totalCount }} resultados para <strong>"{{ searchQuery }}"</strong>
      </div>
      <div v-if="shouldShowAllProductsTitle" class="title-all-products">
        <h2>Nuestros productos</h2>
      </div>
      <div v-if="products?.length" class="all-products">
        <ProductCard v-for="(product, index) in products" :key="index" :product="product" />
      </div>
      <div v-else-if="!products?.length && !loadingProducts" class="empty-products">
        <img src="@/assets/icon-item-default.svg" />
        <h2>{{ emptyProductsText }}</h2>
      </div>
      <div v-if="availableMoreProducts && !loadingProducts" class="more-products">
        <button ref="loadMoreButton" class="button is-outlined" @click="loadMoreProducts">
          Cargar más productos
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import store from '@/store'
import ProductCard from '@/components/products/ProductCard'
import AppLoading from '@/components/shared/Loading'

export default {
  components: {
    AppLoading,
    ProductCard
  },
  methods: {
    ...mapActions('products', ['getProducts']),
    async loadMoreProducts() {
      // Carga mas productos y hace scroll a la posicion del boton antes de traer mas productos.
      const rect = this.$refs.loadMoreButton.getBoundingClientRect()
      const buttonPositionRelativeToPage = rect.top + window.scrollY

      store.set('products/searchPage', this.searchPage + 1)
      await this.getProducts()

      const scrollOffset = 700
      window.scrollTo(0, buttonPositionRelativeToPage - scrollOffset)
    }
  },
  computed: {
    ...mapState('products', [
      'products',
      'loadingProducts',
      'availableMoreProducts',
      'metadata',
      'searchQuery',
      'searchPage',
      'featuredProducts'
    ]),
    ...mapState('categories', ['categorieSelected']),
    ...mapState('shop', ['shop']),
    emptyProductsText() {
      return this.categorieSelected && this.categorieSelected.name
        ? `La categoría ${this.categorieSelected.name}, no tiene productos disponibles`
        : 'No hay productos disponibles'
    },
    shouldShowSearchResultAmount() {
      return this.products?.length && this.searchQuery && this.metadata?.totalCount
    },
    shouldShowAllProductsTitle() {
      return (
        this.featuredProducts?.length &&
        (!this.categorieSelected || !this.categorieSelected.id) &&
        !this.searchQuery
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.all-products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 18px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  }

  // .column {
  //   padding: 0;
  //   margin-right: 10px;
  // }

  // .column:nth-of-type(3n) {
  //   margin-right: 0;
  // }
}

.loader-wrapper {
  margin-top: 80px;
}

.empty-products {
  margin-top: 60px;
  display: flex;
  flex-flow: column;
  align-items: center;

  img {
    height: 130px;
  }

  h2 {
    margin-top: 20px;
    font-size: 20px;
    color: #47536b;
  }
}
.more-products {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 48px;

  button {
    // background: var(--color-tertiary);
    background: #1e293b;
    font-size: 16px;
    font-weight: 600;
    padding: 11px 37px;
    color: #fff;
    height: auto;
    border-radius: 0;
  }
}

.results-search {
  margin-bottom: 20px;
}

.title-all-products {
  margin-bottom: 24px;

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #0f172a;
  }
}
</style>
