const getters = {
  isMobile(state) {
    if (state.windowWidth && state.windowWidth <= 768) {
      return true
    }
    return false
  }
}

export default getters
