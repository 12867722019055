var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "regret-form-modal",
    attrs: {
      "name": "regret-form-modal",
      "adaptive": "",
      "scrollable": "",
      "focusTrap": "",
      "max-width": 480,
      "width": "100%",
      "height": "auto"
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loading-container"
  }, [_c('Loading', {
    attrs: {
      "loading": ""
    }
  })], 1) : _c('div', {
    staticClass: "regret-form-container"
  }, [_c('div', {
    staticClass: "regret-description"
  }, [_c('h4', [_vm._v("Solicitud de arrepentimiento")]), _c('p', [_vm._v(" Según la "), _c('strong', [_vm._v("Resolución 424/2020")]), _vm._v(", tenés 10 días desde la realización de tu compra para solicitar la cancelación. ")]), _c('p', [_vm._v("Ingresá la siguiente información para continuar con tu solicitud.")])]), _c('form', {
    staticClass: "regret-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('label', {
    attrs: {
      "for": "orderNumber"
    }
  }, [_vm._v(" Número de pedido ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.orderNumber,
      expression: "orderNumber",
      modifiers: {
        "trim": true
      }
    }],
    attrs: {
      "type": "text",
      "id": "orderNumber",
      "placeholder": "Ingresá el número de tu pedido..."
    },
    domProps: {
      "value": _vm.orderNumber
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.orderNumber = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.errors.orderNumber ? _c('span', [_vm._v(" " + _vm._s(_vm.errorMessages.orderNumber) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-input"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(" Correo electrónico ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.email,
      expression: "email",
      modifiers: {
        "trim": true
      }
    }],
    attrs: {
      "type": "email",
      "id": "email",
      "placeholder": "Ingresá el correo con el que realizaste la compra..."
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.email = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.errors.email ? _c('span', [_vm._v(" " + _vm._s(_vm.errorMessages.email) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-input"
  }, [_c('label', {
    attrs: {
      "for": "emailConfirm"
    }
  }, [_vm._v(" Confirmá el correo electrónico ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.emailConfirm,
      expression: "emailConfirm",
      modifiers: {
        "trim": true
      }
    }],
    attrs: {
      "type": "email",
      "id": "emailConfirm",
      "placeholder": "Confirma el correo electrónico..."
    },
    domProps: {
      "value": _vm.emailConfirm
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.emailConfirm = $event.target.value.trim();
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.errors.emailConfirm ? _c('span', [_vm._v(" " + _vm._s(_vm.errorMessages.emailConfirm) + " ")]) : _vm._e()]), _c('vue-recaptcha', {
    ref: "recaptcha",
    attrs: {
      "sitekey": _vm.recaptchaSiteKey,
      "size": "invisible"
    },
    on: {
      "verify": _vm.onCaptchaVerified
    }
  }), _c('div', {
    staticClass: "form-buttons"
  }, [_c('button', {
    staticClass: "button-outline",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('regret-form-modal');
      }
    }
  }, [_vm._v("Cancelar")]), _c('button', {
    staticClass: "button-primary"
  }, [_vm._v("Enviar")])])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }