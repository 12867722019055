import categoryService from '@/services/category'
import store from '@/store'

function getAllCategories(lastKey = null) {
  return new Promise(async (resolve, reject) => {
    try {
      const idStore = store.get('shop/shop.id')
      const { categories, metadata } = await categoryService.getCategories(idStore, { lastKey })
      let currentCategories = store.get('categories/categories')
      currentCategories = currentCategories ? currentCategories.concat(categories) : categories
      store.set('categories/categories', currentCategories)
      if (metadata.lastKey) {
        getAllCategories(metadata.lastKey)
      }
      resolve(currentCategories)
    } catch (e) {
      reject(e)
    }
  })
}

const actions = {
  getCategories(context, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        store.set('categories/loadingCategories', true)
        const categories = await getAllCategories()
        store.set('categories/loadingCategories', false)
        resolve(categories)
      } catch (e) {
        reject(e)
      }
    })
  }
}

export default actions
