<template>
  <modal
    name="regret-success-modal"
    class="regret-success-modal"
    adaptive
    scrollable
    focusTrap
    :max-width="320"
    height="auto"
  >
    <div class="regret-success-container">
      <span class="success-icon">
        <!-- prettier-ignore -->
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="53" fill="none"><path fill="#30ABA9" d="M34.796 23.255a1.656 1.656 0 0 0-2.342-2.343l-7.662 7.662-3.246-3.245a1.656 1.656 0 1 0-2.342 2.342l4.416 4.417a1.656 1.656 0 0 0 2.343 0l8.833-8.834Z"/><path fill="#30ABA9" fill-rule="evenodd" d="M27 4.969c-11.891 0-21.531 9.64-21.531 21.531 0 11.891 9.64 21.531 21.531 21.531 11.891 0 21.531-9.64 21.531-21.531 0-11.891-9.64-21.531-21.531-21.531ZM8.781 26.5C8.781 16.438 16.938 8.281 27 8.281c10.062 0 18.219 8.157 18.219 18.219 0 10.062-8.157 18.219-18.219 18.219-10.062 0-18.219-8.157-18.219-18.219Z" clip-rule="evenodd"/></svg>
      </span>

      <p>Tu solicitud de arrepentimiento se envió exitosamente.</p>

      <template v-if="idCancelRequest">
        <p><strong>Número de reclamo</strong></p>

        <h5>{{ idCancelRequest }}</h5>
      </template>

      <p>Te enviamos un correo con toda la información.</p>

      <button @click="$modal.hide('regret-success-modal')">Cerrar</button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RegretSuccessModal',
  computed: {
    ...mapState('order', ['idCancelRequest'])
  }
}
</script>
<style lang="scss">
.regret-success-modal {
  .vm--modal {
    border-radius: 16px;
  }
}
</style>

<style lang="scss" scoped>
.regret-success-container {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  color: #334155;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  h5 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-top: -10px;
  }

  button {
    padding: 10px 20px;
    width: 100%;
    border-radius: 10px;
    background: #30aba9;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    border: none;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      background: #299e9c;
    }
  }
}
</style>
