var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "section_product"
  }, [_c('banner-has-no-iva-days'), _vm.isLoading ? _c('div', {
    staticClass: "product__loading"
  }, [_c('app-loading', {
    attrs: {
      "loading": true
    }
  }, [_c('h4', {
    staticClass: "product__loading-text"
  }, [_vm._v(" Preparando detalles del producto.. "), _c('br'), _vm._v(" ¡Ya casi estamos listos! ")])])], 1) : _vm.hasError ? _c('ProductNotFound') : _vm.productDetail ? _c('div', {
    staticClass: "wrapper-product"
  }, [_c('div', {
    staticClass: "container-detail"
  }, [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column is-8 section-image"
  }, [_c('h1', {
    staticClass: "product-name"
  }, [_vm._v(_vm._s(_vm.productDetail.name))]), _c('div', {
    staticClass: "image-main"
  }, [_vm.mainImageUrl ? _c('img', {
    attrs: {
      "src": _vm.mainImageUrl
    }
  }) : _c('img', {
    staticClass: "image-default",
    attrs: {
      "src": require("@/assets/icon-item-default.svg")
    }
  })]), _vm.discountPercentage ? _c('div', {
    staticClass: "label-discount"
  }, [_vm._v(" " + _vm._s(_vm.discountPercentage) + "% DCTO ")]) : _vm._e()]), _c('div', {
    staticClass: "column is-4 column-collection"
  }, [_c('div', {
    staticClass: "wrapper-top"
  }, [_c('div', {
    staticClass: "container-description"
  }, [_c('div', {
    staticClass: "description"
  }, [_c('h3', {
    staticClass: "label-detail"
  }, [_vm._v("Descripción")]), _c('p', {
    staticClass: "description-text"
  }, [_vm._v(" " + _vm._s(_vm.productDetail.description) + " ")])])])]), _c('div', {
    staticClass: "wrapper-bottom"
  }, [_vm.shouldShowVariants ? _c('div', {
    staticClass: "container-variants"
  }, [_c('app-variant-product', {
    attrs: {
      "product": _vm.productDetail,
      "errorMessage": _vm.errorMessage
    },
    on: {
      "updateVariantsSelected": _vm.updateVariantsSelected
    }
  })], 1) : _vm._e(), _vm.availableStockQuantity || _vm.isProductSoldOut ? _c('div', {
    staticClass: "product__stock"
  }, [_vm.availableStockQuantity && !_vm.isProductSoldOut ? _c('p', {
    staticClass: "product__stock__remaining-stock"
  }, [_vm._v(" Solo "), _c('strong', [_vm._v(_vm._s(_vm.availableStockQuantity) + " unidades")]), _vm._v(" disponibles ")]) : _vm.isProductSoldOut ? _c('p', {
    staticClass: "product__stock__sold-out"
  }, [_vm._v(" PRODUCTO AGOTADO ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "container-price"
  }, [_c('div', {
    staticClass: "level is-flex"
  }, [_c('div', {
    staticClass: "price"
  }, [_vm.productDetail.hasNoIvaDays ? _c('p', {
    staticClass: "labelHasNoIvaDays"
  }, [_vm._v("Sin IVA")]) : _vm._e(), _vm.shouldShowFinalPriceBeforeDiscounts ? _c('p', {
    staticClass: "priceDiscount"
  }, [_vm._v(" " + _vm._s(_vm.$currencyFormatter(_vm.productDetail.finalPriceBeforeDiscounts)) + " ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.$currencyFormatter(_vm.productDetail.finalPrice)))])]), !_vm.isProductSoldOut ? _c('AppMoreOrLessProduct', {
    attrs: {
      "quantity": _vm.quantity,
      "max-quantity": _vm.availableStockQuantity,
      "product": _vm.productDetail,
      "sell-without-stock": _vm.sellWithoutStock
    },
    on: {
      "updateQuantity": _vm.setQuantity
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "container-button"
  }, [_c('button', {
    staticClass: "button is-fullwidth",
    class: {
      'is-loading': _vm.adding
    },
    attrs: {
      "disabled": _vm.shouldDisableAddToCartButton
    },
    on: {
      "click": _vm.checkInventory
    }
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-shopping-car.svg')
    }
  })], 1), _c('span', [_vm._v("AGREGAR AL CARRITO")])])])])])])]), _c('div', {
    staticClass: "columns secondary-images"
  }, [_c('div', {
    staticClass: "column is-8"
  }, [_c('carousel', {
    attrs: {
      "per-page": _vm.isMobile ? 3 : 5,
      "pagination-padding": _vm.isMobile ? 3 : 6,
      "pagination-size": 8,
      "pagination-active-color": "#A3ADC2",
      "mouse-drag": false
    }
  }, _vm._l(_vm.productDetail.images, function (image, index) {
    return _c('slide', {
      key: index,
      staticClass: "slide-image"
    }, [_c('div', {
      staticClass: "secondary-image link",
      class: {
        selected: _vm.imageSelected.id == image.id
      },
      on: {
        "click": function ($event) {
          _vm.imageSelected = image;
        }
      }
    }, [_c('img', {
      attrs: {
        "src": image.url
      }
    })])]);
  }), 1)], 1)])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }