import { make } from 'vuex-pathify'

const inistialState = () => ({
  addedProductModal: { isOpen: false },
  addProductWhithVariantsModal: { isOpen: false, product: null },
  infoModal: { isOpen: false, data: null }
})

const state = inistialState()

const mutations = make.mutations(state)

export default {
  namespaced: true,
  state,
  mutations
}
