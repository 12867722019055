import apiAlegraStoresService from './api-alegra-stores'

const categoryService = {}

categoryService.getCategories = (idStore, { lastKey }) => {
  const params = {
    order: 'priority'
  }
  if (lastKey) {
    params.lastKey = lastKey
  }
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await apiAlegraStoresService.get(`/stores/${idStore}/categories/load-more`, {
        params
      })
      resolve(data)
    } catch (e) {
      reject(e)
    }
  })
}

export default categoryService
