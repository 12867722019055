<template>
  <div class="content-terms">
    <h3>TÉRMINOS Y CONDICIONES </h3>
<h4>Comprador - Alegra Tienda</h4>

<p>Soluciones Alegra S.A.S.(“Alegra”) presta servicios de administración y contabilidad en la nube para Pymes, que les permite facturar, llevar la cuenta de sus gastos, operaciones financieras, inventarios y tener reportes inteligentes de toda la información ingresada, entre otras funcionalidades. Como parte de la oferta de servicios que prestamos, Alegra ha desarrollado el producto denominado “Alegra Tienda” (en adelante “La Herramienta” o “Alegra Tienda”) que permite a los Usuarios de su plataforma crear su propia tienda y vender en línea. Esta Herramienta permite a los Usuarios publicar sus productos ofrecidos a modo de marketplace, o tienda virtual, lo que les facilita gestionar sus procesos comerciales digitalmente, así las transacciones se integren a sus propios módulos contables y financieros en Alegra, facilitando el acceso al comercio electrónico. </p>

<p>Los términos y condiciones establecidos aquí son complementarios a los términos y condiciones generales establecidos por Alegra para la generalidad de sus servicios, que se pueden consultar en <a href="https://www.alegra.com/terminos-y-condiciones/" target="_blank" >https://www.alegra.com/terminos-y-condiciones/</a>. Adicionalmente, cada Usuario podrá tener términos y condiciones aplicables en su tienda online propia, así como políticas de privacidad y de cookies que aplican de manera adicional a los términos establecidos aquí. En caso de contradicción entre los Términos y Condiciones del Usuario y éstos, priman éstos Términos y Condiciones.</p>

<p>Los Términos y Condiciones que estás leyendo tienen como principal objetivo regular las relaciones entre La Plataforma, los Usuarios y los Compradores, los cuales se distribuyen de la siguiente manera:  (i) Definiciones Previas; (ii) Reglas de uso de la página web y demás plataformas (iii) Derechos y Obligaciones; (iv) Responsabilidades; (v) Pagos; (vi) Políticas de cancelación, reembolso y retracto; (vii) Política de Datos Personales y política de privacidad;(viii) Consideraciones finales. </p>

<h4>(i) Definiciones previas</h4>

<li>El término "Contrato" o "Acuerdo" hace referencia a este contrato y a sus Términos y Condiciones.</li>
<li>El término "leyes aplicables" hace referencia a las leyes que se aplican en Colombia para este tipo de contratos.</li>
<li>El término "Sitio" ó "Sitio Web" hace referencia al sitio donde se prestan todos los servicios que Alegra ofrece, sin tener en cuenta posibles terceros relacionados con Alegra.</li>
<li>El término "Servicio" o “Servicios” hace referencia a la aplicación que ofrece Alegra en su sitio web y que opera desde el dominio app.alegra.com. También hace referencia a el uso de la página web de Alegra y su centro de soporte alegra.com/ayuda/</li>
<li>El término "Alegra", "nosotros", "nuestro(a)" hace referencia a Soluciones Alegra S.A.S y todos sus asociados.</li>
<li>El término "Sistema" o “Plataforma” hace referencia a la plataforma web usada por Alegra para prestar su servicio, incluido el software que usa la plataforma y todos sus contenidos.</li>
<li>El término "Aplicación" hace referencia al software que ofrece Alegra como servicio a en su sitio app.alegra.com</li>
<li>El término "Titular de la Cuenta" hace referencia a la persona a nombre de quien está la tarjeta de crédito que se usó para registrar la cuenta en Alegra.</li>
<li>El término "Usuario" hace referencia a la persona que visita y usa el sitio web de Alegra y su aplicación, sea o no "titular de cuenta", y que para los efectos de Alegra Tienda, ofrece sus productos allí, para ser adquiridos por el Comprador.</li>
<li>El término “Comprador” hace referencia a aquellas personas naturales o jurídicas que adquieran productos de los Usuarios a través de Alegra Tienda.</li>
<li>El término “Productos” hace referencia a aquellos bienes ofrecidos por los Usuarios a través de La Herramienta.</li>

<h4>(ii) Reglas de Uso de la Plataforma</h4>

<h4>2.1. Objeto y carácter de los T&C: </h4>

<p>Mediante el uso de Alegra Tienda, el Comprador acepta lo dispuesto en estos Términos y Condiciones, susceptibles de cambio sin previo aviso por parte de Alegra. Si el Comprador representa una organización, está dando por entendido que la organización acepta ceñirse a este contrato y que tiene las facultades para actuar en nombre de aquella y por lo tanto obligarla frente a Alegra y aceptar las obligaciones establecidas en el presente contrato. El Comprador que no esté de acuerdo con este documento, no podrá hacer uso de Alegra Tienda. El Comprador acepta leer, entender y aceptar todas las condiciones establecidas en estos Términos y Condiciones, en las Políticas de Privacidad, así como en los demás documentos incorporados a los mismos por referencia. El desconocimiento del contenido de estos Términos y Condiciones no justifica el incumplimiento de los mismos ni lo exonera de la responsabilidad que le corresponda. </p>

<p>Los Compradores reconocen expresamente que Alegra Tienda se encuentra en fase de lanzamiento, por lo que puede tener cambios, mejoras, actualizaciones y fallas.</p>

<p>Alegra se exime de toda responsabilidad por cualquier imprevisto, reclamación, inconformidad o incumplimiento que pueda surgir entre los Compradores y los Usuarios por causa de la venta del producto correspondiente, por cuanto Alegra es sólo un intermediario que pone a disposición de las partes una plataforma tecnológica para facilitar el contacto entre ellas, mas no tiene un papel activo en las transacciones que se realicen. Alegra se reserva la facultad de tomar todas las medidas legales que su defensa requiera.</p>

<p>Alegra se reserva la facultad y autonomía de modificar estos Términos y Condiciones de manera unilateral, en cualquier momento, y es exclusiva responsabilidad de los Compradores revisar los términos y condiciones al ser publicados, difundidos, transmitidos, comunicados o enviados a ellos por el medio que determinemos, y aceptarlos, de ser el caso, o solicitar la baja de la plataforma en caso de no ser aceptados. </p>

<h4>2.2.  Condiciones de uso:</h4>

<p>Para el uso autorizado de Alegra Tienda, el Comprador deberá suministrar toda aquella información y/o documentación requerida por Alegra y/o El Usuario, la cual estará protegida de conformidad con la política de tratamiento de datos. En consecuencia, el Comprador debe manifestar expresamente su consentimiento a los presentes T&C y demás documentos concordantes. </p>

<p>Alegra Tienda servirá de vitrina de exhibición de los productos ofertados por los Usuarios. Cada Usuario podrá tener una tienda virtual, sobre la que se cargarán los productos que tenga registrados en los Servicios Alegra.</p>

<p>Los Usuarios deberán publicar todas y cada una de las características, garantías, condiciones y restricciones que aplican para cada uno de los productos ofertados en  Alegra Tienda. De lo contrario, no podrán ser aplicables tales condiciones y restricciones, y podría existir una infracción al régimen de protección del consumidor vigente. Alegra no tendrá ninguna responsabilidad sobre la no correspondencia entre el producto ofertado, y el recibido, de ser éstos distintos, en la calidad de los mismos, en la publicidad, ni en las cantidades registradas dentro del inventario. </p>

<p>Los Usuarios se obligan a realizar los envíos de los Productos a los Compradores  en el menor tiempo posible y cumplir con los tiempos estipulados de entrega. Alegra no se hace responsable por demoras en el envío y recepción de los productos adquiridos. El valor del envío de los Productos será asumido por el Comprador, salvo que el Usuario determine algo distinto. </p>

<p>El Usuario reconoce que está obligado a cumplir con lo ordenado por la ley 1480 de 2011 (Estatuto del Consumidor). Para todos los efectos legales, los proveedores y distribuidores son los únicos responsables legalmente ante posibles infracciones al mencionado régimen. El Usuario se compromete a mantener a La Empresa indemne ante posibles incumplimientos legales.</p>

<p>En caso de reclamo, el Comprador deberá dirigirse directamente al Usuario por los canales que éste tenga dispuestos para tal efecto. Ante incumplimientos al Estatuto del Consumidor, La Empresa podrá penalizar al Usuario y expulsarlo de Alegra Tienda.</p>

<p>El Comprador se compromete a no usar el Sitio Web con fines fraudulentos, así como a no llevar a cabo conducta alguna que pudiera estafar, hurtar, dañar la imagen, los intereses, propósitos y los derechos de las partes, de Alegra o de terceros.</p>

<p>Se informa a los Compradores que en el caso de que incumpla el contenido de los presentes Términos y Condiciones, de la Política de Privacidad o de cualesquiera otros términos o condiciones particulares, Alegra podrá limitar, suspender, bloquear o terminar su acceso al Servicio Alegra y/o a Alegra Tienda, adoptando cualquier medida técnica que sea necesaria con ese fin.</p>

<h4>2.3. Capacidad:</h4>

<p>El Comprador, con la aceptación de los presentes T&C, declara que cuenta con toda la capacidad legal y estatutaria para la suscripción y adhesión de los mismos. Así mismo, mantendrá indemne a Alegra de cualquier reclamación relacionada con la capacidad de los acá firmantes. </p>

<h4>2.4. Veracidad de la información: </h4>

<p>El Comprador deberá diligenciar la información que le sea solicitada con datos válidos. Se podrán utilizar diversos medios para identificar a los Compradores, pero Alegra NO se responsabiliza por la certeza ni veracidad de los Datos Personales provistos por los Compradores. Los Compradores garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los Datos Personales ingresados.</p>

<p>A su exclusiva discreción, Alegra podrá solicitar documentación a quienes hagan uso de la plataforma web, sean personas jurídicas o naturales, y podrá compartir aquella que sea necesaria para cumplir con sus obligaciones a cabalidad.</p>

<p>Los Compradores serán responsables por todas las operaciones efectuadas en su Cuenta, así como las transferencias de dinero a los Usuarios. A su vez, los Usuarios se obligan a entregar los productos en óptimas condiciones, a respetar las garantías y demás derechos del Comprador, en su calidad de consumidor. </p>

<p>No obstante lo anterior, los Compradores mantendrán a Alegra indemne de cualquier reclamación, ya que es una plataforma de intermediación que no tiene responsabilidad sobre el cumplimiento de las obligaciones de la relación comercial entre Usuarios y Compradores. </p>

<p>Alegra se reserva el derecho de rechazar un Comprador, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.</p>

<p>Alegra podrá modificar los Términos y Condiciones en cualquier momento, haciendo pública en La Plataforma la nueva versión. Cuando sean modificados, entrarán en vigor a los 5 (cinco) días de su publicación. Todo Comprador que no esté de acuerdo con las modificaciones efectuadas podrá solicitar la baja de la cuenta.</p>

<p>El uso del sitio y/o sus servicios implica la aceptación de estos Términos y Condiciones generales de uso de La Plataforma. </p>

<h4>(iii) Derechos y obligaciones</h4>

<h4>3.1. Derechos de los Compradores: </h4>

<p>Los Compradores tendrán los siguientes derechos:</p>

<li>Recibir toda la información sobre los Productos ofrecidos por los Usuarios</li>
<li>Ejercer derecho de retracto de conformidad con lo establecido en el Estatuto del Consumidor. Este se realizará directamente ante el Usuario.</li>
<li>Recibir cualquier información sobre cambios en las condiciones del negocio, o del Producto adquirido o deseado. </li>
<li>Manifestar sus opiniones ante el Usuario con respecto del Producto adquirido. </li>
<li>Los demás derivados de los presentes T&C y de legislación aplicable. </li>

<h4>3.2. Obligaciones de los Compradores: </h4>

<p>Serán obligaciones de los Compradores, los siguientes:</p>

<li>Aceptar los presentes T&C y la política de tratamiento de datos para hacer uso de Alegra Tienda</li>
<li>Brindar toda la información y/o documentación de manera clara, con miras a facilitar la debida ejecución del negocio con el Usuario.</li>
<li>Realizar los pagos relacionados con la adquisición del producto del Usuario.</li>
<li>Mantener indemne a Alegra de cualquier reclamación judicial o extrajudicial relacionada con la adquisición de producto, toda vez que reconoce expresamente que Alegra es un intermediario entre el Comprador y el Usuario. </li>
<li>Las demás derivadas de los presentes T&C y política de tratamiento de datos. </li>

<h4>3.3. Derechos del Usuario:</h4>

<p>Serán derechos del Usuario, los siguientes:</p>

<li>Recibir el pago oportuno por los Productos que ofrezca por medio de la plataforma.</li>
<li>Actualizar su información con miras a notificar en debida forma a los Compradores cualquier información relevante para la correcta ejecución del contrato regulado por estos T&C.</li>
<li>Las demás derivadas de los presentes T&C y política de tratamiento de datos. </li>

<h4>3.4. Obligaciones del Usuario: </h4>

<h4>Serán Obligaciones del Usuario:</h4>

<li>Entregar toda la información y/o documentación necesaria para acreditar su calidad de Usuario. </li>
<li>Cumplir a cabalidad con las obligaciones derivadas de la compraventa de los Productos ofrecidos a los Compradores, incluidas las garantías y los términos de envío de los productos.</li>
<li>Cumplir con toda la normatividad legal y administrativa aplicable. </li>
<li>Mantener indemne de cualquier reclamación a Alegra.</li>
<li>Cumplir y garantizar el derecho de retracto a favor de los Compradores.</li>
<li>Cumplir el régimen de protección al consumidor vigente. </li>
<li>Las demás derivadas de los presentes Términos y Condiciones y política de tratamiento de datos. </li>

<h4>(iv) Responsabilidades </h4>

<p>Alegra no tendrá responsabilidad alguna por daños, perjuicios o incumplimientos de cualquier clase, que surjan como consecuencia de la utilización de La Plataforma, o que sea causado directa o indirectamente por actuaciones u omisiones del Usuario. </p>

<h4>4.1 Responsabilidad de Alegra: </h4>

<li>La Plataforma pretende conectar al Usuario con el Comprador, por ende, no se hace responsable de las transferencias, validación de descuentos, contenido, información, alcance y resultados de las transacciones que se realicen; Sin embargo, como facilitador, procederá a llevar a cabo conductas que permitan el debido cumplimiento de las obligaciones de cada una de las partes. Así las cosas, Alegra se compromete a tener una plataforma funcional a disposición de Las Partes para su uso y acceso, salvo casos en donde ocurran fallas técnicas, que no sean responsabilidad directa de Alegra.</li>

<li>Alegra, por medio de su plataforma, proporcionará al Usuario y al Comprador toda la información y tecnología para que entre estos puedan compartir toda la información relacionada con la transacción realizada entre las partes. </li>

<h4>4.2 Responsabilidad de los Compradores:</h4>

<li>Los Compradores se comprometen a leer completamente los presentes Términos Y Condiciones y formular oportunamente cualquier duda e inquietud relacionada con los mismos. </li>
<li>Los Compradores se comprometen a realizar la transferencia de dinero correspondiente al precio establecido por el Usuario, por el medio que el Usuario establezca.  </li>
<li>Los Compradores se comprometen a entregar toda la información de manera veraz y aquella documentación que sea indispensable para la buena gestión por parte del Usuario.  </li>
<li>Reconocen expresamente la posibilidad de que los Usuarios entren en procesos de liquidación o cierre total. </li>

<h4>(v) VALOR Y FORMA DE PAGO:</h4>

<h4>5.1. Valor y Forma de pago: </h4>

<p>Cuando Alegra lo considere, podrá poner a disposición de Las Partes una pasarela de pago de un tercero que permita la realización de la transacción financiera entre ellas, por lo que no tendrá responsabilidad alguna sobre las comisiones que cobre el proveedor por las transacciones realizadas, comisiones de entidades bancarias o franquicias de tarjeta de crédito, ni por el mal funcionamiento de la pasarela de pagos. Si la pasarela de pagos todavía no se encuentra integrada, será responsabilidad de cada Usuario establecer las formas de pago que recibirán, para tal efecto queda bajo su propia responsabilidad el entregar la información completa para realizar las transferencias o consignaciones por parte de los Compradores.</p>

<p>El Usuario es libre de determinar el valor de los productos ofertados en su sitio.</p>

<h4>5.2. Declaraciones frente al pago:  </h4>

<li>Los recursos con los cuales se efectuó el pago provienen de operación, oficio, profesión, actividad o negocio lícitos.</li>
<li>Los Compradores nunca han permitido que terceras personas utilicen sus cuentas o tarjetas de crédito o débito para consignar o administrar dineros que desconozcan su origen o provengan de conductas contrarias a la ley y especialmente a la ley penal.</li>
<li>Los Compradores eximen a Alegra de toda responsabilidad por la información errónea, falsa, inexacta que se hubiese suministrado en su proceso de pago del servicio o curso contratado. </li>

<h4>Política de cancelación, retracto y reversión </h4>

<h4>6.1 Derecho de retracto del Comprador:</h4>

<p>Para hacer efectivo el derecho de retracto luego de la adquisición de un producto en La Plataforma, y dentro del término de CINCO (5) días hábiles, el Comprador deberá acudir directamente al Usuario y seguir el procedimiento establecido en el Estatuto del Consumidor. </p>

<p>A su vez, el Usuario declara aceptar y reconocer la aplicación del derecho de retracto a favor del Comprador.</p>

<h4>6.2 Reversión:</h4>

<p>Lo mismo se aplicará en los casos donde El Comprador solicite la reversión del pago por operaciones fraudulentas realizadas por medio de su tarjeta de crédito, débito o cualquier otro instrumento de pago electrónico. El Comprador deberá dar aviso al Usuario y a su correspondiente entidad bancaria dentro los CINCO (5) días hábiles siguientes desde que tuvo conocimiento de tal situación. </p>

<h4>(vii) Política de manejo de datos personales y política de privacidad</h4>

<h4>7.1 Política de manejo de datos personales:</h4>
<p>Alegra es un aplicativo Web, el cual proporciona un sitio donde se alojan datos que el usuario administra bajo su cuenta y riesgo. Alegra vela por mantener la información de los Usuarios y Compradores segura, y toma las precauciones que sean necesarias a su juicio para ello, más no se responsabiliza por actos mal intencionados de terceros y las consecuencias de ello frente al usuario, compradores o frente a terceros.</p>
<p>Alegra pone en conocimiento de sus usuarios y compradores, que la información que suministre se albergará en servidores de terceros, específicamente propiedad de Amazon, que cumplen con los más altos estándares de seguridad e idoneidad.</p>
<p>Se da a entender que el Usuario o Comprador, al usar los servicios de Alegra, hará un uso sano y legal de todas las herramientas que se ponen a su disposición y esto exonera a Alegra de cualquier uso indebido de su información.</p>
<p>Alegra no estará obligada a velar por la legalidad del contenido e información que los Usuarios y Compradores alberguen en su cuenta, sin embargo podrá tomar los correctivos en contra de información ilegal, cuando lo considere pertinente.</p>
<p>El Usuario y el Comprador se obliga, a la hora de usar Alegra Tienda o cualquiera de los servicios de Alegra a (i) no causar daño físico, moral o mental a otros usuarios del servicio (ii) no utilizar el servicio con fines maliciosos o de mala voluntad, ni para beneficiarse en perjuicio de terceros, (iii) no usar el servicio con fines criminales o ilegales ni para sacar provecho o beneficio ilícito para sí o para terceros, bien sea remunerado o no, (iv) no publicar información que vulnere derechos de terceros, tales como derechos de propiedad intelectual, secretos industriales o cualquier otro que sea de propiedad de terceros y respecto de los cuales no se encuentre autorizado (v) publicar información sensible, que ya no es vigente o que pueda inducir a error a terceros.</p>
<p>El Usuario manifiesta ser dueño de la información que ingresa en el sistema y bajo ninguna circunstancia esta información pasará a ser propiedad de Soluciones Alegra S.A.S. De igual manera, Alegra es la única dueña de la plataforma sin que se pueda considerar realizada ningún tipo de transferencia alguna en razón de este contrato. Si el Usuario da por terminado el contrato tendrá la información a su alcance, pero en ningún momento Alegra se obliga a entregar la misma en formato alguno ni a llevar a cabo ningún tipo de proceso de migración, ni mucho menos a efectuar un desarrollo para que la información pueda ser analizada, ingresada o digitalizada en cualquier otro software, pues sólo se ingresan datos, para su consulta y procesamiento en aras de su funcionamiento y uso.</p>
<p>Adicional a lo anterior, Alegra no se responsabiliza por el mal diligenciamiento de un formulario o por la información que erróneamente suministre el Usuario al momento de realizar un trámite o de ingresar la información al software.</p>
<p>Con el fin de permitir una mejor explicación del uso de datos personales por parte nuestra, diríjase a nuestra POLÍTICA DE DATOS PERSONALES publicada en la página https://www.alegra.com/terminos-y-condiciones/#pt-i,  para comprender el manejo concreto que realizamos de sus datos y los canales de comunicación que dispone para ejercer sus derechos.</p>

<h4>7.2 Política de privacidad:</h4>
<h4>a) Funciones publicitarias de Google Analytics implementadas en Alegra</h4>

<p>Alegra utiliza las audiencias de remarketing de Google Analytics para su uso en Google AdWords y DoubleClick Bid Manager, esto con el fin de publicar campañas de remarketing dirigidas a sus usuarios. Así mismo, Alegra hace uso de la red publicitaria de Facebook, y los códigos de tracking de Hubspot. El usuario acepta que su información sea tratada para efectos de Big Data y por lo tanto permite que Alegra utilice estas herramientas con fines estadísticos.</p>
<h4>b) Modo de uso de las cookies</h4>
<p>Alegra, mediante la instalación de cookies e identificadores propios y de terceros y a través de herramientas para el análisis de uso de cuenta, podrá realizar actividades de seguimiento a los usuarios que utilizan su aplicación. Con estas herramientas, Alegra podrá recopilar información de cada usuario y comprador para el análisis de estadísticas e implementación de estrategias de comunicación y publicidad. Estas herramientas podrán realizar seguimiento de la configuración del usuario y del comprador y hacen que su experiencia en la aplicación sea más práctica, reconociendo y recordando sus preferencias y ajustes.  El listado completo de es de terceros usadas por Alegra se podrán encontrar <a target="_blank" href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSfeC7skVjRd2SAhLPJ_DUFFz3E6ENqHVX96zMePq2dBnLEoMiAGwUYnbFfSJ9k6Q4xlO3O241q13at/pubhtml">aquí.</a></p>
<p>En esta medida, Alegra podrá recopilar datos de uso, como la duración de uso, o datos demográficos como el origen, el sexo y la edad. Alegra usa esta información para fines analíticos. El usuario de Alegra podrá deshabilitar estas herramientas.</p>
<p>Las actividades de seguimiento adelantadas por Alegra también podrán realizarse dentro de la aplicación, para análisis interno, pero no exclusivamente para determinar tendencias y participación en la aplicación, tiempo de uso, preferencias, entre otros. Estas herramientas podrán ser desactivadas por el usuario para conservar su privacidad. </p>
<h4>c) Análisis de datos.</h4>
<p>Al hacer uso de Alegra Tienda, acepta que ésta pueda suministrar, transferir, compartir y analizar los datos de las compras realizadas con empresas aliadas, filiales, vinculadas o subordinadas en Colombia o cualquier otro país. Esta información podrá ser usada para fines comerciales por parte de estos terceros.</p>
<p>El Comprador, en cualquier momento, puede solicitarle a Alegra que detenga el proceso de compartir sus datos con terceros, para esto debe realizar su solicitud al correo soporte@alegra.com o comunicarse a través del chat de atención al cliente.</p>

<h4>Consideraciones finales</h4>

<h4>8.1 SUSPENSIÓN Y TERMINACIÓN DEL SERVICIO. </h4>
<p>Alegra podrá terminar de manera unilateral la cuenta de un Comprador en los siguientes escenarios: (i) En caso de que el Comprador utilice los servicios prestados por Alegra para fines contrarios a la ley, especialmente aquellos que contraríen derechos de propiedad intelectual de terceros y sobre todo de Alegra y de otros usuarios; (ii) En caso de que Alegra encuentre que el Comprador está haciendo uso de su cuenta para la transmisión de programas malignos como virus, malwares, spywares, troyanos o similares, que puedan comprometer el debido funcionamiento de la plataforma de Alegra o que perjudiquen a terceros; (iii) Cuando existan elementos que permitan inferir a Alegra que el Comprador no cuenta con la edad mínima para contratar.</p>
<p>Alegra se reserva el derecho a decidir si el contenido publicado por los usuarios, al igual que el material de texto o fotográfico que sea cargado a la página web de Alegra resulta apropiado y se ajusta a las Condiciones. En éste sentido, Alegra podrá impedir la publicación y comercialización de contenido que infrinja derechos de imagen, de habeas data y de privacidad de terceros, así como aquellos que resulten ofensivos, difamatorios o que constituyan infracciones a la ley</p>

<h4>8.2 EXCLUSIÓN DE LA RELACIÓN LABORAL. </h4>

<p>Se entiende que entre Las Partes firmantes del presente contrato no existe relación laboral alguna. </p>

<h4>8.3 TÍTULO EJECUTIVO. </h4>

<p>Los presentes Términos Y Condiciones prestan mérito ejecutivo, permitiendo a Alegra iniciar las acciones judiciales y extrajudiciales respectivas, a fin de lograr el efectivo cumplimiento de las obligaciones en ellas contenidas.</p>

<h4>8.4 PUNTO DE CONTACTO. </h4>

<p>Cualquier duda e inquietud relacionada con los presentes Términos Y Condiciones será atendida en los siguientes puntos de contacto:</p>

<li>Dirección: Carrera 43 C N° 5 - 115 </li>
<li>Ciudad: Medellín, Colombia</li>
<li>Teléfono:+574 4480132 </li>
<li>Correo Electrónico:  soporte@alegra.com</li>

<h4>8.5 LEY APLICABLE. </h4>

<p>Este contrato se rige por las leyes de la República de Colombia. Si cualquier parte de este contrato se declara nula o contraria a la ley, entonces la parte inválida o no exigible se considerará sustituida por una disposición válida y aplicable que más se acerque a la intención del contrato original y el resto del acuerdo continuará tal como está estipulado. A menos que se especifique lo contrario en este documento, estas Condiciones constituyen el acuerdo completo entre el comprador, el Usuario y Alegra y reemplaza a todas las comunicaciones previas y propuestas, tanto de manera electrónica, oral o escrita, entre el Usuario, el Comprador y Alegra.</p>

<h4>8.6 RESOLUCIÓN DE CONTROVERSIAS.</h4>

<p>Cualquier controversia que surja de lo establecido en los presentes será resuelta en primer término mediante un acercamiento amistoso. En caso de no lograrse acuerdo alguno, ambas partes podrán acudir a los tribunales de la República de Colombia. </p>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  metaInfo() {
    const title = 'Tienda - Términos y condiciones'
    const description = 'Términos y condiciones'
    // const title = `Términos y condiciones${(this.shop ? ' - ' + this.shop.name : '')}`
    return {
      title,
      meta: [
        { name: 'title', content: title },
        { property: 'og:title', content: title },
        { name: 'description', content: description },
        { property: 'og:description', content: description },
        { property: 'og:site_name', content: title },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://${this.shop.domain}${this.$route.path}` },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  },
  computed: {
    ...mapState('shop', ['shop'])
  }
}
</script>

<style lang="scss" scoped>
.content-terms {
  text-align: justify;
  margin: 0 50px 400px 50px;
}
h3 {
  margin: 25px 0;
  font-weight: 700;
}
h4 {
  margin: 20px 0;
  font-weight: 600;
}
li {
  margin: 8px 15px;
}
p {
  margin: 15px 0;
}

@media (max-width: 768px) {
  .content-terms {
    text-align: justify;
    margin: 30px 20px 600px 20px;
  }
}
</style>
