var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_vm.paymentMethod ? _c('div', {
    staticClass: "container-item payment"
  }, [_vm._m(0), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "columns is-mobile"
  }, [_c('div', {
    staticClass: "column is-1"
  }, [_c('span', {
    staticClass: "icon",
    class: _vm.paymentMethod.provider ? _vm.paymentMethod.provider.toLowerCase() : ''
  }, [_c('img', {
    attrs: {
      "src": _vm.iconPayment
    }
  })])]), _c('div', {
    staticClass: "column is-11 has-text-left container-text"
  }, [_c('h2', [_vm._v(_vm._s(_vm.paymentMethod.name))]), _c('p', {
    staticClass: "additional-name"
  }, [_vm._v("Al confirmar tu pedido recibirás instrucciones para realizar el pago.")])])])])]) : _vm._e(), _vm.client ? _c('div', {
    staticClass: "container-item contact"
  }, [_vm._m(1), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column has-text-left"
  }, [_c('span', [_vm._v(_vm._s(_vm.client.name))])])]), _c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column has-text-left"
  }, [_c('span', [_vm._v(_vm._s(_vm.client.email))])]), _c('div', {
    staticClass: "column"
  }, [_c('span', [_vm._v(_vm._s(_vm.client.phone))])])])])]) : _vm._e(), _vm.address ? _c('div', {
    staticClass: "container-item shipping"
  }, [_vm._m(2), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column has-text-left"
  }, [_c('span', [_vm._v(_vm._s(_vm.address.address))])]), _c('div', {
    staticClass: "column"
  }, [_c('span', [_vm._v(_vm._s(_vm.address.city))])])]), _vm.address.zipcode ? _c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column has-text-left"
  }, [_c('span', [_vm._v(_vm._s(_vm.address.zipcode))])])]) : _vm._e(), _vm.address.additionalInfo ? _c('div', {
    staticClass: "columns"
  }, [_c('div', {
    staticClass: "column has-text-left"
  }, [_c('span', [_vm._v(_vm._s(_vm.address.additionalInfo))])])]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "row row-center terms"
  }, [_c('p', [_vm._v(" Al confirmar tu pedido, aceptas los "), _c('span', {
    staticClass: "link",
    on: {
      "click": function ($event) {
        return _vm.goTerms();
      }
    }
  }, [_vm._v("Términos y condiciones de Alegra Tienda.")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-dollar.svg")
    }
  })]), _c('h2', [_vm._v("INFORMACIÓN DE PAGO")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-user.svg")
    }
  })]), _c('h2', [_vm._v("DATOS DE CONTACTO")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icon-truck.svg")
    }
  })]), _c('h2', [_vm._v("DATOS DE ENVÍO")])]);
}]

export { render, staticRenderFns }